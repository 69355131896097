import { DateMeta } from "../../../lib/meta/date/date";
import { IDMeta } from "../../../lib/meta/id/id";
import { LongTextMeta } from "../../../lib/meta/longText/longText";
import { SimpleTextMeta } from "../../../lib/meta/simpleText/simpleText";
import { ResourceConfig } from "../../../lib/types/resource";
import { ClinicalStudy } from "./clinicalStudy";
import {Typography} from "@mui/material";
import React from "react";
import {clinicalStudyFilter} from "./clinicalStudyFilter";
import {ClinicalStudiesDataProvider} from "./clinicalStudiesDataProvider";
import {NumberMeta} from "../../../lib/meta/number/number";
import GenericSearch from "../../genericSearch";
import {NoArgsAction} from "../../../lib/actions/noArgsAction";
import {ActionType} from "../../../lib/types/action";
import {DefaultShowTabView} from "../../../lib/views/crud/show/defaultTab";


interface ConfirmAction {
    text: string
}

export const ClinicalStudiesResource: ResourceConfig<ClinicalStudy, GenericSearch> = {
    id: "clinicalStudies",
    label: "Clinical Studies",
    actions: [
        new NoArgsAction<ConfirmAction, ClinicalStudy>({
            id: "delete",
            label: "Delete",
            hidden: (res) => {
                if (res) {
                    return (res as ClinicalStudy).files_count !== 0
                }
                return !res;
            },
            handler: (data, dataProvider?,) => {
                dataProvider = (dataProvider as unknown as ClinicalStudiesDataProvider);
                return dataProvider &&
                    dataProvider
                        .delete("id" in data ? data.id : "")
                        .then(r => {
                            return r
                        })
            },
            choiceAction: {
                confirmLabel: "Delete",
                cancelLabel: "Cancel",
                description: "This clinical study has no linked files and can be deleted.",
            },
            actionStyle: {
                buttonVariant: 'outlined',
                buttonColor: 'error'
            },
            actionType: ActionType.Single
        })
    ],
    dataProvider: new ClinicalStudiesDataProvider(),
    filter: clinicalStudyFilter,
    fields: [
        {
            id: "id",
            label: "ID",
            meta: new IDMeta()
        },
        {
            id: "name",
            label: "Name",
            meta: new SimpleTextMeta({}),
        },
        {
            id: "description",
            label: "Description",
            meta: new LongTextMeta(),
        },
        {
            id: "created_at",
            label: "Created At",
            meta: new DateMeta({}),
        },
        {
            id: "updated_at",
            label: "Updated At",
            meta: new DateMeta({}),
        },
        {
            id: "files_count",
            label: "Files",
            meta: new NumberMeta({isLink: true})
        },
        {
            id: "patients_count",
            label: "Patients",
            meta: new NumberMeta({})
        }
    ],
    create: {
        fields: [
            {
                id: "name",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Name is required."
                    }
                },
            },
            {
                id: "description",
                validationOptions: {
                    minLength: {
                        value: 50,
                        message: "Description is too short."
                    },
                    required: {
                        value: true,
                        message: "Description is required."
                    },
                }
            }
        ]
    },
    list: {
        id: "clinical_studies",
        pageSize: 10,
        fields: [{id: "id"}, {id: "name"}, {id: "description"}, {id: "files_count"}, {id: "patients_count"}]
    },
    show: {
        component: <DefaultShowTabView
                        id={"clinicalStudies"}
                        tabs={[{label: "info", fields: ["id", "name", "description", "files_count", "patients_count"]}]}
                        fields={[{id: "id"},{id: "name"}, {id: "description"}, {id: "files_count"}, {id: "patients_count"}]}
                        previousPage={-1}
                        getTitle={(data?:ClinicalStudy) => {
                            return (
                                <>
                                    <Typography variant={"pageTitle"}
                                                style={{
                                                    width: "fit-content",
                                                    maxWidth: "15em",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}
                                    >/ {data?.name}</Typography>
                                </>)
                        }}
         />,
        fields: [{id: "id"},{id: "name"}, {id: "description"}, {id: "files_count"}, {id: "patients_count"}]
    },
    edit: undefined,
    description: "List of the clinical studies currently managed via miWeb.",
    isDeletable: () => false,
    primaryKey: "id",
    groupId: "appManager"
}
