import { createTheme, Typography } from "@mui/material";
import { NoArgsAction } from "../../../lib/actions/noArgsAction";
import { DateMeta } from "../../../lib/meta/date/date";
import { EmailMeta } from "../../../lib/meta/email/email";
import { IDMeta } from "../../../lib/meta/id/id";
import { OneToMany } from "../../../lib/meta/oneToMany/oneToMany";
import { SimpleTextMeta } from "../../../lib/meta/simpleText/simpleText";
import { ActionType } from "../../../lib/types/action";
import { ResourceConfig } from "../../../lib/types/resource";
import { DefaultShowTabView } from "../../../lib/views/crud/show/defaultTab";
import { ButtonsVariant, CustomTheme } from "../../../theme";
import {Audit, AuditFilter} from "../audit/audit";
import { AuditResource } from "../audit/auditResource";
import { User } from "./user";
import React from "react";
import {DefaultEditTabView} from "../../../lib/views/crud/edit/defaultTab";
import {userFilter} from "./userFilter";
import { UserDataProvider } from "./userDataProvider";
import IDataProvider from "../../../hoc/dataProvider/IDataProvider";
import axios from "axios";
import { BASE_URL } from "../../../App";
import GenericSearch from "../../genericSearch";
import {StateMeta} from "../../../lib/meta/state/state";
import {auditFilter} from "../audit/auditFilter";
import {roleInfoLink} from "./hyperlinkConfigs/roleInfo";
import {TermsAndConditions} from "../../profile/profile";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const theme = createTheme(CustomTheme(), ButtonsVariant());


export const UserResource: ResourceConfig<User, GenericSearch> = {
    id: "users",
    label: "Users",
    actions: [
        new NoArgsAction<{}, User>(
            {
                id: "resetLogin",
                label: "Reset login attempts",
                choiceAction: {
                    confirmLabel: "confirm",
                    cancelLabel: "cancel",
                    description: "Do you want to reset the number of login attempts for this user?",
                },
                handler: async (res, dataProvider?: IDataProvider<User, User>, _authProvider?, feedbackCtx?) => {
                    let user = (res as User);
                    user.reset_login_attempts = true;
                    return axios.put<User>(`${BASE_URL}/users/${user.id}/update`, 
                                            user, 
                                            dataProvider?.getHeaders ? {headers: await dataProvider.getHeaders({"Accept": "application/json"})} : undefined)
                    .then((resp) => {
                        feedbackCtx?.openBottomSuccessSnackbar("Login attempts reset successfully.")
                        return resp.data
                    });
                },
                hidden: (user) => {
                    if (user) {
                        return (user as User).status !== 'active'
                    }
                    return !user;
                },
                actionType: ActionType.Single
            }
        ),
        new NoArgsAction<{}, User>(
            {
                id: "reactivate",
                label: "Reactivate",
                choiceAction: {
                    confirmLabel: "confirm",
                    cancelLabel: "cancel",
                    description: "Are you sure you want to reactivate this user?",
                },
                handler: async (res, dataProvider?: IDataProvider<User, User>, _authProvider?, feedbackCtx?) => {
                    let user = (res as User);
                    user.status = 'active';
                    return axios.put<User>(`${BASE_URL}/users/${user.id}/update`, 
                                            user, 
                                            dataProvider?.getHeaders ? {headers: await dataProvider.getHeaders({"Accept": "application/json"})} : undefined)
                    .then((resp) => {
                        feedbackCtx?.openBottomSuccessSnackbar("Profilo riattivato.")
                        return resp.data
                    });
                },
                hidden: (user) => {
                    if (user) {
                        return (user as User).status !== 'disabled'
                    }
                    return !user;
                },
                actionType: ActionType.Single
            }
        ),
        new NoArgsAction<{}, User>(
            {
                id: "resendInvite",
                label: "Resend Invite",
                choiceAction: {
                    confirmLabel: "confirm",
                    cancelLabel: "cancel",
                    description: "Resend the invitation link to this user.",
                },
                handler: async (res, dataProvider?: IDataProvider<User, User>, _authProvider?, feedbackCtx?) => {
                    let user = (res as User);
                    return axios.put<User>(`${BASE_URL}/users/${user.id}/resend_invitation`,
                        user,
                        dataProvider?.getHeaders ? {headers: await dataProvider.getHeaders({"Accept": "application/json"})} : undefined)
                        .then((resp) => {
                            feedbackCtx?.openBottomSuccessSnackbar("Invite sent successfully.")
                            return resp.data
                        });
                },
                hidden: (user) => {
                    const u = user as User;
                    return !u || u.status === "active" || u.status === "disabled";
                },
                actionType: ActionType.Single
            }
        ),
        new NoArgsAction<{}, User>(
            {
                id: "disableMFA",
                label: "Reset 2FA",
                choiceAction: {
                    confirmLabel: "Confirm",
                    cancelLabel: "Cancel",
                    description: "Are you sure you want to reset the two-factor authentication for this user?",
                },
                handler: async (res, dataProvider?: IDataProvider<User, User>, _authProvider?, feedbackCtx?) => {
                    let user = (res as User);
                    user.disable_mfa = true;
                    user.mfa_enabled = false;
                    user.mfa_confirmed = false;
                    return axios.put<User>(`${BASE_URL}/users/${user.id}/update`,
                        user,
                        dataProvider?.getHeaders ? {headers: await dataProvider.getHeaders({"Accept": "application/json"})} : undefined)
                        .then((resp) => {
                            feedbackCtx?.openBottomSuccessSnackbar("Two-factor authentication reset successfully.")
                            return resp.data
                        });
                },
                hidden: (user) => {
                    if (user) {
                        return !(user as User).mfa_enabled || (user as User).status !== "active"
                    }
                    return !user;
                },
                actionType: ActionType.Single
            }
        ),
        new NoArgsAction<{}, User>(
            {
                id: "disable",
                label: "Disable",
                choiceAction: {
                    confirmLabel: "confirm",
                    cancelLabel: "cancel",
                    description: "Are you sure you want to disable this user? You will be able to reactivate it later.",
                },
                actionStyle: {
                    buttonVariant: 'outlined',
                    buttonColor: 'error'
                },
                handler: async (res, dataProvider?: IDataProvider<User, User>, _authProvider?, feedbackCtx?) => {
                    let user = (res as User);
                    user.status = 'disabled';
                    return axios.put<User>(`${BASE_URL}/users/${user.id}/update`,
                        user,
                        dataProvider?.getHeaders ? {headers: await dataProvider.getHeaders({"Accept": "application/json"})} : undefined)
                        .then((resp) => {
                            feedbackCtx?.openBottomSuccessSnackbar("Profile disabled successfully.")
                            return resp.data
                        });
                },
                hidden: (user) => {
                    if (user) {
                        return (user as User).status === 'disabled'
                    }
                    return !user;
                },
                actionType: ActionType.Single
            }
        )
    ],
    dataProvider: new UserDataProvider(),
    filter: userFilter,
    fields: [
        {
            id: "id",
            label: "ID",
            meta: new IDMeta()
        },
        {
            id: "first_name",
            label: "Name",
            meta: new SimpleTextMeta({})
        },
        {
            id: "last_name",
            label: "Surname",
            meta: new SimpleTextMeta({})
        },
        {
            id: "email",
            label: "E-mail",
            meta: new EmailMeta()
        },
        {
            id: "group",
            label: "Role",
            meta: roleInfoLink(theme)
        },
        {
            id: "group_name",
            label: "Role",
            meta: roleInfoLink(theme)
        },
        {
            id: "exp_date",
            label: "Expiry Date",
            meta: new DateMeta({
                valuer: (data) => {
                    const date = new Date((data as User).exp_date);
                    return [date.getDate(), date.getMonth() + 1, date.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('/')
                },
                minDate: new Date()
            })
        },
        {
            id: "status",
            label: "State",
            meta: new StateMeta({stateBadge: (data: User) => {
                    const today = new Date();
                    let expDate;
                    if (data?.exp_date) {
                        expDate = new Date(data.exp_date)
                    }
                    return (
                        <Typography
                            variant={"stateBadge"}
                            style={{
                                color: data.status === "disabled" || (expDate && today > expDate) ? theme.palette.error.main :
                                       data.status === "active" ? theme.palette.success.main :
                                       data.status === "invited" ? theme.palette.warning.main :  "",
                            }}>{expDate && today > expDate? "EXPIRED" : data.status?.toUpperCase()}
                        </Typography>
                            )
                }})
        },
        {
            id: "terms_and_conditions",
            label: "Contract signed on",
            meta: new SimpleTextMeta({
                valuer: (data) => {
                    const termsData = data as unknown as TermsAndConditions
                    if (!termsData.date) {
                        return (
                            <div style={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                                <CancelIcon color={"error"} fontSize={"small"}/>
                            </div>
                        )
                    } else {
                        const date = new Date(termsData.date?.toString()!);
                        return  <div style={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                            {[date.getDate(), date.getMonth() + 1, date.getFullYear()]
                                    .map(n => n < 10 ? `0${n}` : `${n}`)
                                    .join('/')+", "+
                                [date.getHours(), date.getMinutes(), date.getSeconds()]
                                    .map(n => n < 10 ? `0${n}` : `${n}`)
                                    .join(":")}<CheckCircleIcon color={"success"} fontSize={"small"}/>
                        </div>
                    }
                }
            })
        },
        {
            id: "log",
            label: "Log",
            meta: new OneToMany<User, Audit, AuditFilter>({
                resource: AuditResource,
                defaultFilter: (data) => {return {user_id: data.id} as AuditFilter},
                otherFilters: auditFilter.filters.filter((f) => f.field.id !== "user_id"),
                fields: [
                    {
                        id: "created_at",
                        label: "Date",
                        meta: new DateMeta({})
                    },
                    {
                        id: "action",
                        label: "Action",
                        meta: new SimpleTextMeta({})
                    },
                    {
                        id: "object",
                        label: "Target",
                        meta: new SimpleTextMeta({})
                    },
                    {   id: "object_id",
                        label: "Target ID",
                        meta: new SimpleTextMeta({})
                    }
                    ],
                pageSize: 10
            })
        },
        {
            id: "mfa_enabled",
            label: "MFA enabled",
            meta: new SimpleTextMeta({})
        }
    ],
    list: {
        id: "users",
        pageSize: 10,
        fields:[{id:"id"}, {id:"first_name"}, {id:"last_name"}, {id:"email"}, {id:"status"}]
    },
    show: {
        component: <DefaultShowTabView
            id={"users"}
            tabs={[
                    {
                        label: "Data",
                        fields: ["id", "first_name", "last_name", "email", "group_name", "exp_date", "terms_and_conditions"]
                    },
                    {
                        label: "Log",
                        fields: ["log"],
                        isDisabled: (user) => (user as User).status === "invited"
                    }
                ]
            }
            previousPage={-1}
            getTitle={(data?:User) => {
                const badgeValue = data?.status;
                const today = new Date();
                let expDate;
                if (data?.exp_date) {
                    expDate = new Date(data.exp_date)
                }
                return (
                    <>
                        <Typography variant={"pageTitle"}
                                    style={{
                                        width: "fit-content",
                                        maxWidth: "15em",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}
                        >/ {data?.first_name + " " + data?.last_name}&nbsp;</Typography>
                        <Typography
                            variant={"stateBadge"}
                            style={{
                                color: badgeValue === "disabled" || (expDate && today > expDate) ? theme.palette.error.main :
                                    badgeValue === "active" ? theme.palette.success.main :
                                    badgeValue === "invited" ? theme.palette.warning.main : "",
                                marginLeft: "1rem"
                            }}>{expDate && today > expDate? "EXPIRED" : badgeValue?.toUpperCase()}
                        </Typography>
                    </>)
            }}
        />,
        fields:[{id:"id"}, {id:"first_name"}, {id:"last_name"}, {id:"email"}, {id: "group_name"}, {id:"exp_date"}, {id: "mfa_enabled"}, {id:"status"}, {id: "terms_and_conditions"}]
    },
    create:{
        pageSize: 5,
        fields:[
            {
                id:"first_name",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Name is required."
                    }
                },
            },
            {
                id:"last_name",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Surname is required."
                    }
                },
            },
            {
                id:"email",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Email address is required."
                    },
                    pattern: {
                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}(\.[a-zA-Z]{2,4})?$/,
                        message: "Email format is invalid."
                    }
                },
            },
            {
                id:"group",
                validationOptions: {
                    required: {
                        value: true,
                        message: "group is required."
                    }
                }
            },
            {
                id:"exp_date",
                validationOptions: {
                    required: false,
                    pattern: {
                        value: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
                        message: "Invalid date."
                    }
                }
            }
        ]
    },
    edit: {
        component: <DefaultEditTabView id={"users"}
                   tabs={[
                       {
                           label: "data",
                           fields: ["id", "first_name", "last_name", "group", "exp_date"]
                       },
                       {
                           label: "log",
                           fields: [],
                           isDisabled: true
                       }
                   ]}
                   getTitle={(data?:User) => {
                       const badgeValue = data?.status;
                       const today = new Date();
                       let expDate;
                       if (data?.exp_date) {
                           expDate = new Date(data.exp_date)
                       }

                       return (
                           <>
                               <Typography variant={"pageTitle"}>/ {data?.first_name + " " + data?.last_name}&nbsp;</Typography>
                               <Typography
                                   variant={"stateBadge"}
                                   style={{
                                       color: badgeValue === "disabled" || (expDate && today > expDate) ? theme.palette.error.main :
                                           badgeValue === "active" ? theme.palette.success.main :
                                           badgeValue === "invited" ? theme.palette.warning.main : "",
                                       marginLeft: "1rem"
                                   }}>{expDate && today > expDate? "EXPIRED" : badgeValue?.toUpperCase()}
                               </Typography>
                           </>)
                   }}/>,
        fields:[
            {id:"id"},
            {
                id:"first_name",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Name is required."
                    }
                },
            },
            {id:"last_name",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Name is required."
                    }
                },
            },
            {id: "group",
                validationOptions: {
                    required: {
                        value: true,
                        message: "Role is required."
                    }
                },
            },
            {id: "exp_date"}
        ]
    },
    description: "List of active and disabled users who have been invited to miWeb.",
    primaryKey: "id",
    groupId: "appManager"
}
