import {ResourceConfig} from "../../../lib/types/resource";
import React from "react";
import {MetadataFile, MetadataFileFilter, MetadataFileStatus} from "./metadataFile";
import {StateMeta} from "../../../lib/meta/state/state";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import {SimpleTextMeta} from "../../../lib/meta/simpleText/simpleText";
import {DateMeta} from "../../../lib/meta/date/date";
import {DefaultShowTabView} from "../../../lib/views/crud/show/defaultTab";
import {createTheme, Typography} from "@mui/material";
import {OneToMany} from "../../../lib/meta/oneToMany/oneToMany";
import {Audit, AuditFilter} from "../../appManager/audit/audit";
import {AuditResource} from "../../appManager/audit/auditResource";
import {auditFilter} from "../../appManager/audit/auditFilter";
import {ButtonsVariant, CustomTheme} from "../../../theme";
import {Upload} from "@mui/icons-material";
import {MetadataFilesUploadView} from "../../../components/labTest/metadataFiles/upload/upload";
import {StepAction} from "../../../lib/actions/stepAction";
import {ActionType} from "../../../lib/types/action";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {NoArgsAction} from "../../../lib/actions/noArgsAction";
import {MetadataFilesDataProvider} from "./dataProvider";
import {userInfoLink} from "../../appManager/audit/hyperlikConfigs/userInfo";
import {metadataFileFilter} from "./metadataFileFilter";
import {LongTextMeta} from "../../../lib/meta/longText/longText";
import {SingleArgsAction} from "../../../lib/actions/singleArgsAction";

const theme = createTheme(CustomTheme(), ButtonsVariant());

interface ConfirmAction {
    text: string
}

export const MetadataFileResource: ResourceConfig<MetadataFile, MetadataFileFilter> = {
    id: "metadataFiles",
    label: "Metadata files",
    filter: metadataFileFilter,
    dataProvider: new MetadataFilesDataProvider(),
    customRoute: {
        path: "upload",
        component: <MetadataFilesUploadView/>
    },
    actions: [
        new StepAction<{}, MetadataFile>({
            id: "upload",
            label: "Upload",
            actionType: ActionType.Step,
            icon: <FileUploadIcon fontSize={"medium"}/>,
            hidden: () => false
        }),
        new NoArgsAction<ConfirmAction, MetadataFile>({
            id: "download",
            label: "Download",
            hidden: (data, authNCtx) => {
                if (!authNCtx) return true;
                return  (!data || (data as MetadataFile).status === MetadataFileStatus.ERROR)
            },
            handler: (data, dataProvider, _authProvider?, feedbackCtx?) => {
                const lsfData = data as MetadataFile;
                const lsfDataProvider = (dataProvider as unknown as MetadataFilesDataProvider);
                return lsfDataProvider &&
                    lsfDataProvider
                        .download(lsfData.id)
                        .then(r => {
                            const blob = new Blob([r], {type: "application/octet-stream"});
                            const href = URL.createObjectURL(blob);
                            const download = Object.assign(document.createElement('a'), {
                                href: href,
                                download: lsfData.name
                            });
                            document.body.append(download);
                            download.click();
                            download.remove();
                            URL.revokeObjectURL(href);
                            feedbackCtx?.openBottomSuccessSnackbar("Download successful.")
                            return r
                        })
            },
            choiceAction: {
                confirmLabel: "Download",
                cancelLabel: "Cancel",
                description: "",
            },
            modalDescription: () => {
                return "Do you want to download this file?"
            },
            actionStyle: {
                buttonVariant: 'outlined',
                buttonColor: 'primary'
            },
            actionType: ActionType.Single
        }),
        new SingleArgsAction<MetadataFile, MetadataFile>({
            id: "delete",
            label: "Delete",
            hidden: (data) => {
                return !data
            },
            handler: (data, dataProvider, _authProvider, feedbackCtx, formData) => {
                const lsfDataProvider = (dataProvider as unknown as MetadataFilesDataProvider);
                const sfData = data as MetadataFile;
                if (sfData.status === MetadataFileStatus.CANCELED || sfData.status === MetadataFileStatus.ERROR) {
                    return lsfDataProvider
                        .hardDelete("id" in data ? data.id : "", true, formData)
                        .then(r => {
                            feedbackCtx?.openBottomSuccessSnackbar("Source file successfully deleted.")
                            return r
                        })
                } else {
                    return dataProvider &&
                        dataProvider
                            .delete("id" in data ? data.id : "")
                            .then(r => {
                                feedbackCtx?.openBottomSuccessSnackbar("Source file successfully deleted.")
                                return r
                            })
                }
            },
            choiceAction: {
                confirmLabel: "Delete",
                cancelLabel: "Cancel",
                description: "",
            },
            modalDescription: (data) => {
                const sfData = data as MetadataFile;
                if (sfData.status === MetadataFileStatus.CANCELED) {
                    return `Are you sure you want to PERMANENTLY delete this file? \n All the tests linked to this file will also be removed.`

                } else {
                    return "Are you sure you want to cancel this file? You will still be able to download it."
                }
            },
            actionStyle: {
                buttonVariant: 'outlined',
                buttonColor: 'error'
            },
            fields: [
                {id: "id", label: "Why do you want to delete this metadata file?*",
                    meta: new LongTextMeta<any>(),
                    hidden: (data?: MetadataFile) => data?.status !== MetadataFileStatus.CANCELED && data?.status !== MetadataFileStatus.ERROR,
                    validationOptions: {
                        required: {
                            value: true,
                            message: "This field is required."
                        }
                    }}
            ],
            actionType: ActionType.Single
        })
    ],
    fields: [
        {
            id: "status",
            label: "State",
            meta: new StateMeta({stateBadge: (data: MetadataFile) => {
                    return (
                        <div>
                            {
                                data.status === MetadataFileStatus.PROCESSED ?
                                    <CheckCircleIcon color={"success"} fontSize={"large"}/>
                                    :
                                    data.status === MetadataFileStatus.CANCELED ?
                                        <CancelIcon color={"error"} fontSize={"large"}/>
                                        :
                                        data.status === MetadataFileStatus.ERROR ?
                                            <WarningIcon color={"warning"} fontSize={"large"}/>
                                            :
                                            data.status === MetadataFileStatus.PROCESSING ?
                                                <Upload color={"primary"} fontSize={"large"}/> : <></>
                            }
                        </div>
                    )
                }})
        },
        {
            id: "tag_name",
            label: "Name",
            meta: new SimpleTextMeta({})
        },
        {
            id: "created_at",
            label: "Upload date",
            meta: new DateMeta({})
        },
        {
            id: "uploaded_by",
            label: "Uploaded by",
            meta: new SimpleTextMeta({})
        },
        {
            id: "clinical_study_name",
            label: "Clinical Study",
            meta: new SimpleTextMeta({})
        },
        {
            id: "laboratory_name",
            label: "Laboratory",
            meta: new SimpleTextMeta({})
        },
        {
            id: "log",
            label: "Audit",
            meta: new OneToMany<MetadataFile, Audit, AuditFilter>({
                resource: AuditResource,
                defaultFilter: (data) => {return {object_id: data.id, object: "LAB_SOURCE_FILE"} as AuditFilter},
                otherFilters: auditFilter.filters.filter((f) => f.field.id !== "object_id" && f.field.id !== "object"),
                fields: [
                    {
                        id: "created_at",
                        label: "Date",
                        meta: new DateMeta({})
                    },
                    {
                        id: "action",
                        label: "Action",
                        meta: new SimpleTextMeta({})
                    },
                    {
                        id: "user_id",
                        label: "User ID",
                        meta: userInfoLink()
                    },
                    {
                        id: "object",
                        label: "Target",
                        meta: new SimpleTextMeta({})
                    }
                ],
                pageSize: 10
            })
        },
    ],
    tabPosition: "left",
    list: {
        id: "lab_source_file",
        pageSize: 20,
        fields: [{id: "status"}, {id: "tag_name"}, {id: "created_at"}, {id: "clinical_study_name"}, {id: "laboratory_name"}]
    },
    show: {
        component: <DefaultShowTabView id={"metadataFiles"}
                                       tabs={[
                                           {
                                               label: "Metadata",
                                               fields: ["tag_name", "created_at", "uploaded_by", "clinical_study_name", "laboratory_name"]
                                           },
                                           {
                                               label: "Audit",
                                               fields: ["log"]
                                           }
                                       ]}
                                       getTitle={(data?: MetadataFile) => {
                                           const badgeValue = data?.status;
                                           return (
                                               <>
                                                   <Typography variant={"pageTitle"}
                                                               style={{
                                                                   width: "fit-content",
                                                                   maxWidth: "15em",
                                                                   whiteSpace: "nowrap",
                                                                   overflow: "hidden",
                                                                   textOverflow: "ellipsis"
                                                               }}
                                                   >/ {(data as MetadataFile)?.tag_name}</Typography>
                                                   <Typography
                                                       variant={"stateBadge"}
                                                       style={{
                                                           color: badgeValue === MetadataFileStatus.PROCESSED ? theme.palette.success.main :
                                                                  badgeValue === MetadataFileStatus.ERROR ? theme.palette.warning.main :
                                                                  badgeValue === MetadataFileStatus.CANCELED ? theme.palette.error.main :
                                                                  badgeValue === MetadataFileStatus.PROCESSING ? theme.palette.primary.main : "",
                                                           marginLeft: "1rem"
                                                       }}>{badgeValue?.toUpperCase()}
                                                   </Typography>
                                               </>
                                           )
                                       }}
                                       previousPage={-1}
        />,
        fields: [{id: "tag_name"}, {id: "created_at"}, {id: "uploaded_by"}, {id: "clinical_study_name"}, {id: "laboratory_name"}]
    },
    primaryKey: "id",
    description: "List of the metadata files relative to the laboratory tests present on miWeb.",
    groupId: "lab",
    isDeletable: () => false
}
