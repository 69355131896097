import AddIcon from "@mui/icons-material/Add";
import {Button, CircularProgress, Grid, Typography} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ListTable from "../../../components/table/ListTable";
import {useResourceContext} from "../../../context/ResourceContext";
import {ActionType} from "../../../types/action";
import {ResourceConfig} from "../../../types/resource";
import {DefaultStyles} from "./defaultStyles";
import {Field} from "../../../types/field";
import {PagedResponse} from "../../../../types/pagedResponse";
import {useAuthenticationContext} from "../../../context/AuthenticationContext";
import FiltersComponent from "../../../components/filters/filtersComponent/filtersComponent";

interface DefaultListProps<T> {
    fields: Field<T>[];
    pageSize?: number;
    optionalParam?: keyof T;
}

export function DefaultListView<T, V>({fields, pageSize, optionalParam}: DefaultListProps<T>) {
    const classes = DefaultStyles();
    const navigate = useNavigate();
    const authCtx = useAuthenticationContext();
    const [data, setData] = useState<T[] | undefined>(undefined);
    const [nextPageToken, setNextPageToken] = useState("");

    const [filter, setFilter] = useState<V | undefined>(undefined);

    const [mainFilter, setMainFilter] = useState<V | undefined>(undefined);
    const [initialData, setInitialData] = useState<T[] | undefined>(undefined);

    const [selectedData, setSelectedData] = useState<T[] | undefined>(undefined);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    const resourceCtx = useResourceContext();
    const resource = resourceCtx.getConfig() as ResourceConfig<T, V>;

    const isPaginated = pageSize !== undefined;

    useEffect(() => {
        initData();
    }, [filter, mainFilter]);

    const initData = () => {
        if (resource.filter?.filters.find(f => f.rendersElement) && mainFilter) {
            if (isPaginated) {
                resource?.dataProvider?.pagedList(pageSize, undefined, {...mainFilter, ...filter} as V)
                    .then((data: PagedResponse<T>) => {
                        setInitialData(data.data);
                        setNextPageToken(data.page_token);
                    })
                    .catch((errStatus) => {
                        if (errStatus === 401) {
                            authCtx.setAuthenticated(false);
                        }
                    });
            } else {
                resource?.dataProvider?.list(pageSize, {...mainFilter, ...filter} as V).then((data: T[]) => {
                    setInitialData(data);
                });
            }
        }
        if (!resource.filter?.filters.find(f => f.rendersElement) ||
            (resource.filter?.filters.find(f => f.rendersElement) && filter)) {
            if (isPaginated) {
                resource?.dataProvider?.pagedList(pageSize, undefined, {...mainFilter, ...filter} as V)
                    .then((data: PagedResponse<T>) => {
                        setData(data.data);
                        setNextPageToken(data.page_token);
                    })
                    .catch((errStatus) => {
                        if (errStatus === 401) {
                            authCtx.setAuthenticated(false);
                        }
                    });
            } else {
                resource?.dataProvider?.list(pageSize, {...mainFilter, ...filter} as V).then((data: T[]) => {
                    setData(data);
                });
            }
        }
    }

    const getFilter = (filter: V | undefined) => {
        setFilter(filter)
    }
    const getMainFilter = (filter: V | undefined) => {
        setMainFilter(filter)
    }

    const getAllData = (res: boolean) => {
        setAllSelected(res)
        if (res === true && data) {
            if (!isPaginated) {
                setSelectedData(data)
            } else {
                // empty array on purpose (selection for paginated lists is managed differently)
                setSelectedData([])
            }
        }
    }

    const handleNextPage = () => {
        resource?.dataProvider?.pagedList(pageSize, nextPageToken, {...mainFilter, ...filter} as V).then((data: PagedResponse<T>) => {
            setData((prevData) => {
                return prevData?.concat(data.data);
            })
            setInitialData((prevData) => {
                return prevData?.concat(data.data);
            })
            setNextPageToken(data.page_token);
        });
    }

    const handleSelectionChange = (data: T[]) => {
        setSelectedData([...data])
    }

    if (!resource) {
        return <CircularProgress/>
    }

    return (
        <>
            <Grid container className={classes.mainContainer}>
                <Grid container direction={"column"}>
                    <Grid item>
                        <Grid container direction={"row"} className={classes.titleContainer}>
                            {}
                            <Typography variant={"pageTitle"}>{resource?.label}</Typography>
                            <Grid>
                                <>
                                    {resource.actions &&
                                        resource.actions.filter((a) => a.type === ActionType.Redirect)
                                            .map((a, index) => {
                                                if (a.redirectionRoute) {
                                                    return (
                                                        <Button key={index} onClick={() => navigate(a.redirectionRoute as unknown as number)}>
                                                            {a.icon}{a.label}
                                                        </Button>
                                                    )
                                                }
                                            })
                                    }
                                </>
                                {resource.create &&
                                    <Button variant="outlined"
                                            onClick={() => {
                                                navigate(`create`);
                                            }}>
                                        <AddIcon/>
                                        Add {resource.label}
                                    </Button>
                                }
                                {resource.actions &&

                                    <>
                                        {resource.actions
                                            .filter((action) => action.type === ActionType.Step)
                                            .map((action, index) => {
                                                if (!resource.filter?.filters.find((f) => f.rendersElement)) {
                                                    return <Fragment key={index}>{action.getActionComponent(
                                                        data as T[] ?? initialData as T[] ?? undefined
                                                    )({})}</Fragment>
                                                }
                                                return resource.filter?.filters
                                                    .filter((f) => f.rendersElement)
                                                    .map((f, i) => {
                                                        return <Fragment key={i}>{action.getActionComponent(
                                                            data as T[] ?? initialData as T[] ?? undefined,
                                                            (!data || data?.length === 0)  && (!initialData || initialData?.length === 0),
                                                            mainFilter && `${f.field.id.toString()}=${mainFilter[f.field.id as keyof V]}`)({})}
                                                        </Fragment>
                                                    })
                                            })}
                                        {
                                            resource.actions?.filter((a) => a.type === ActionType.Bulk)
                                                .map((a, index) => {
                                                    a.onComplete = () => {initData()};
                                                    return <Fragment key={index}>{a.getActionComponent(
                                                        selectedData,
                                                        (!isPaginated && (selectedData === undefined || selectedData.length === 0)) ||
                                                        (isPaginated && (selectedData === undefined || (selectedData.length === 0 && !allSelected))),
                                                        {...filter}
                                                    )({})}</Fragment>
                                                })
                                        }
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant={"pageSubtitle"}>{resource?.description}</Typography>
                    </Grid>
                </Grid>
                <FiltersComponent data={data} getFilter={getFilter} getMainFilter={getMainFilter} resource={resource}/>
                {(!resource.filter?.filters.find(f => f.rendersElement) ||
                        (resource.filter?.filters.find(f => f.rendersElement) && mainFilter)) &&
                    <>
                        {
                            data || initialData?
                                <Grid item className={classes.tableContainer} width='100%'>
                                    <ListTable
                                        data={filter? data! : mainFilter? initialData! : data!}
                                        showFields={resource.fields.filter(f => fields.find(ff => ff.id === f.id))}
                                        pageSize={initialData?.length && initialData.length < 5 ? initialData.length : 5}
                                        checkboxSelection={!!resource.actions?.find((action) => action.type === ActionType.Bulk)}
                                        primaryKey={resource.primaryKey}
                                        getAllData={getAllData}
                                        handleViewDetail={(row) => navigate(optionalParam ? `${row[resource.primaryKey]}?${optionalParam.toString()}=${row[optionalParam]}` : `${row[resource.primaryKey]}`)}
                                        onDataSelected={handleSelectionChange}
                                        loadMore={isPaginated ? {
                                            loadMoreCallback: handleNextPage,
                                            loadMoreText: () => nextPageToken === "" ? "No more data to show" : "LOAD MORE",
                                            disableLoadMore: !nextPageToken
                                        } : undefined}

                                    />
                                </Grid>
                                :
                                <CircularProgress/>

                        }

                    </>
                }

            </Grid>
        </>
    )
}
