import React, {ReactElement, useState} from "react";
import {DefaultModal} from "../../../lib/components/modal/defaultModal";
import {useModalContext} from "../../../lib/context/ModalContext";
import {useFeedbackContext} from "../../../lib/context/FeedbackContext";
import {useLabTestDataProvider} from "../../../resources/labTest/hooks";
import {LabTest} from "../../../resources/labTest/labTest/labTest";
import {CircularProgress, Grid} from "@mui/material";
import axios from "axios";

interface DownloadModalProps {
    data: LabTest
}
export const DownloadTestModal = ({data}: DownloadModalProps): ReactElement => {
   const modalCtx = useModalContext();
    const feedbackCtx = useFeedbackContext()

    const [loading, setLoading] = useState<boolean>(false);

    const dataProvider = useLabTestDataProvider();

    const handleDownload = () => {
        setLoading(true)
        dataProvider.download(data.id)
                    .then(r => {
                        const link = r.temporary_link;
                        axios.get(link, { responseType: 'arraybuffer' })
                            .then((res) => {
                                const blob = new Blob([res.data], { type: "application/octet-stream" });
                                const href = URL.createObjectURL(blob);
                                const download = Object.assign(document.createElement('a'), {
                                    href: href,
                                    download: data.name
                                });
                                document.body.appendChild(download);
                                download.click();
                                document.body.removeChild(download);
                                URL.revokeObjectURL(href);
                                setLoading(false)
                                feedbackCtx?.openBottomSuccessSnackbar("Download successful.");
                                modalCtx?.closeModal();
                            })
                            .catch(() => {
                                console.error("Error downloading file.");
                            });
                        return r;
                    })
                    .catch(() => {
                        console.error("Error downloading file.");
                    });
    }

    return (
        <DefaultModal
            title={"Download lab test"}
            description={loading? "File downloading..." : "Do you want to download this file?"}
            handleCancel={() => modalCtx?.closeModal()}
            handleConfirm={handleDownload}
            cancelLabel={"Cancel"}
            confirmLabel={"Download"}
            confirmButtonDisabled={loading}
            cancelButtonDisabled={loading}
        >
            <Grid container justifyContent={"center"}>
                {
                    loading &&
                        <CircularProgress />
                }
            </Grid>
        </DefaultModal>
    )
}
