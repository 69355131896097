import React, {useEffect, useState} from "react";
import {AppBar, Tabs, Grid, Typography, createTheme, useMediaQuery} from "@mui/material";
import {NavbarStyles} from "./NavbarStyles";
import {useLocation, useNavigate} from "react-router-dom";
import {useAdminContext} from "../../context/AdminContext";
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {ButtonsVariant, CustomTheme} from "../../../theme";
import {useAuthenticationContext} from "../../context/AuthenticationContext";
import {useModalContext} from "../../context/ModalContext";
import {DefaultModal} from "../modal/defaultModal";
import {useAuthorizationContext} from "../../context/AuthorizationContext";

export interface Tab {
    label: string;
    onClick: () => void;
    image?: string;
    active?: boolean;
}

export interface NavbarProps {
    children: React.ReactNode;
    tabs?: Tab[];
}

function Navbar(props: NavbarProps) {
    const classes = NavbarStyles();
    const [hoveredTab, setHoveredTab] = useState(-1);
    const navigate = useNavigate();
    const location = useLocation();
    const [tabs, setTabs] = useState<Tab[]>();
    const adminContext = useAdminContext();
    const theme = createTheme(CustomTheme(), ButtonsVariant());

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const authenticationContext = useAuthenticationContext();
    const modalCtx = useModalContext()
    const authorizationContext = useAuthorizationContext();

    const smallerScreen = useMediaQuery(theme.breakpoints.down("xl"));

    useEffect(() => {
        const groupId = location.pathname.split("/")[1];
        const tabs: Tab[] = adminContext.getResourcesByGroup(groupId)
            .map((resource) => {
                return {
                    label: resource.label,
                    onClick: () => {
                        navigate(`/${groupId}/${resource.id}`)
                    },
                    active: location.pathname.includes(resource.id),
                    position: "left"
                }
            })

        const activeTab = tabs.findIndex((tab) => tab.active);
        setActiveTabIndex(activeTab >= 0 ? activeTab : 0);

        setTabs(tabs);
    }, [location.pathname, adminContext.getResources()]);
    const handleLogout = () => {
        modalCtx?.openModal({
            component: <DefaultModal
                title={"Logout"}
                description={"Do you want to log out?"}
                handleCancel={() => modalCtx?.closeModal()}
                confirmLabel={"Logout"}
                cancelLabel={"Cancel"}
                buttonStyles={{
                    buttonVariant: "outlined",
                    buttonColor: "error"
                }}
                handleConfirm={() => {
                    authenticationContext.logout(false).then(() => {
                        modalCtx?.closeModal()
                        navigate("/")
                    })
                }}
            />
        })

    }

    const handleHover = (index: number) => {
        setHoveredTab(index);
    }



    return (
        <Grid container>
            <AppBar className={classes.wrapper} elevation={0}>
                <Grid container direction={"row"} className={classes.internalWrapper}>
                    <Grid>
                        <Tabs value={activeTabIndex}>
                            {tabs && (props.tabs ?? tabs).map((tab: Tab, index: number) => (
                                <Grid container direction="column"
                                      key={tab.label}
                                      className={`${classes.tab} ${index === hoveredTab || tab.active ? classes.selectedTab : ""}`}
                                      onMouseEnter={() => handleHover(index)}
                                      onMouseLeave={() => handleHover(-1)}
                                      onClick={() => {
                                          tab.onClick();
                                          localStorage.removeItem("tabValue")
                                      }}
                                >
                                    <Typography variant={"tabTitle"}
                                                className={tab.active ? classes.selectedTabTitle : classes.tabTitle}>
                                        {tab.image === undefined && tab.label.toUpperCase()}
                                    </Typography>
                                </Grid>
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid container className={classes.profileTab} onClick={() => {
                                navigate(`/home/profile/`)
                            }}>
                                <Grid item>
                                    <Grid container className={classes.profileTabContent}
                                          display={"flex"} alignItems={"center"}
                                    >
                                        <Grid item lg={2}>
                                            <AccountCircleIcon fontSize={smallerScreen? "large" : "medium"} style={{color: theme.palette.primary.main}}/>
                                        </Grid>
                                        { !smallerScreen &&
                                            <Grid item lg={9} display={"flex"} flexDirection={"column"}>
                                            <Typography textTransform={"uppercase"} noWrap variant={"smallButtonText"}
                                                        className={classes.profileTabTitle}>
                                                {authorizationContext.getRoleName()}
                                            </Typography>
                                            <Typography noWrap variant={"tabTitle"}
                                                        className={classes.profileTabSubtitle}>
                                                {authenticationContext.getUserName()}
                                            </Typography>
                                        </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    className={classes.exitTab} onClick={() => {
                                    handleLogout()
                                }}>
                                    <LogoutIcon className={classes.exitTabIcon}
                                    ></LogoutIcon>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AppBar>
            <Grid container>
                {props.children}
            </Grid>
        </Grid>
    );
}

export default Navbar;
