import {createStyles, makeStyles} from "@mui/styles";

const SourceFilePreloadStyles = makeStyles(() =>
    createStyles({
        mainContainer: {
            marginTop: "2em",
            marginBottom: "-4em",
            display: 'flex !important',
            position: "relative",
            width:"75vw",
            overflowX: 'scroll'
        },
        innerContainer: {
            marginBottom:"1em"
        },
        card: {
            width:"20em !important",
            height:"25em",
            marginRight:"0.5em",
            marginLeft:"0.5em",
            marginTop:"0.5em"
        },
        switch: {
            marginTop:"1em !important",
            alignSelf:"end",
            alignItems:"center",
            marginRight:"7% !important"
        },
        select: {
            marginTop:"1.5em !important",
            width:"80%",
            alignSelf:"center"
        },
        columnsList: {
            marginTop:"1.5em !important",
            width:"80%",
            alignSelf:"center"
        },
        columnTitle: {
            justifyContent:"center !important",
            height: "3.5em",
            backgroundColor: "#DDDDDD"
        },
        columnTitleText: {
            marginRight: "0.5rem !important"
        },
        editIcon: {
            cursor: "pointer !important"
        }
    }));

export {SourceFilePreloadStyles};
