import React, {ReactElement, useEffect, useState} from "react";
import {
    Box, Button,
    CircularProgress,
    Grid, InputAdornment,
    List, ListItem, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
    TextField, Typography
} from "@mui/material";
import {useModalContext} from "../../../lib/context/ModalContext";
import {DefaultModal} from "../../../lib/components/modal/defaultModal";
import {ProfileStyles} from "../profileStyles";
import KeyIcon from "@mui/icons-material/Key";
import {ApiKey, useApiKeysDataProvider} from "./apiKeysProvider";
import DeleteIcon from '@mui/icons-material/Delete';
import {useForm} from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {useFeedbackContext} from "../../../lib/context/FeedbackContext";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";


export const ApiKeysModal = (): ReactElement => {
    const classes = ProfileStyles();
    const modalCtx = useModalContext();
    const feedbackCtx = useFeedbackContext();

    const {handleSubmit} = useForm<ApiKey>({mode: "all"});

    const [loading, setLoading] = useState<boolean>(true);
    const [keyGenerationView, setKeyGenerationView] = useState<boolean>(false);
    const [accessKeys, setAccessKeys] = useState<ApiKey[]>([]);

    const [name, setName] = useState<string>();
    const [expirationDate, setExpirationDate] = useState<Date>();

    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState<number>(0);
    const rowsPerPage = 3;
    const [token, setToken] = useState<string>();
    const [copied, setCopied] = useState<boolean>(false);
    const [confirmDeletion, setConfirmDeletion] = useState<string>("");

    const apiKeysDataProvider = useApiKeysDataProvider();

    useEffect(() => {
        getAllAccessKeys();
    }, [])

    const getAllAccessKeys = () => {
        apiKeysDataProvider.getList().then((res) => {
            if (res.api_keys) {
                const sortedKeys = res.api_keys.sort((a, b) => {
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                });
                setAccessKeys(sortedKeys);
            }
            setLoading(false);
        });
    };

    const generateAccessKey = () => {
        setCopied(false)
        if (!name) return;
        apiKeysDataProvider.create(name, expirationDate && new Date(expirationDate.setDate(expirationDate?.getDate()))).then((res) => {
            setToken(res.token);
            setAccessKeys([...accessKeys, res].sort((a, b) => {
                return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            }));
            setName("");
            setExpirationDate(undefined);
            setKeyGenerationView(false);
            feedbackCtx.openBottomSuccessSnackbar("Key generated successfully.")
        }).catch(() => {
            feedbackCtx.openBottomErrorSnackbar("Error: the key was not generated.")
        });
    };

    const deleteAccessKey = (key: string) => {
        apiKeysDataProvider.delete(key).then(() => {
            getAllAccessKeys();
            feedbackCtx.openBottomSuccessSnackbar("Key deleted successfully.")
        })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleCopy = (key: string) => {
        navigator.clipboard.writeText(key).then(() => {
            setCopied(true);
            feedbackCtx.openBottomSuccessSnackbar("Key copied to clipboard.")
        });
    }

    const generateKeyTable = () => {
        return (
            <>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.cell}>Name</TableCell>
                                <TableCell className={classes.cell}>Revoked</TableCell>
                                <TableCell className={classes.cell}>Expires on</TableCell>
                                <TableCell className={classes.smallCell}> </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
                <TableBody>
                    {
                        filteredKeys
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((key, index) => {
                                return (
                                    <>
                                        <TableRow
                                            key={key.access_key}
                                            className={index === 0 && token && !copied ? classes.highlightedRow : ''}
                                        >
                                            <TableCell className={classes.cellWrapper}>
                                                <div className={classes.longText}>
                                                    {key.name}
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.cell}>{key.revoked_at ? "Y" : "N"}</TableCell>
                                            <TableCell className={classes.cell}>
                                                {key.expiration_date?
                                                    [new Date(key.expiration_date).getDate(), new Date(key.expiration_date).getMonth()+1, new Date(key.expiration_date).getFullYear()].join('/')
                                                    : "--"
                                                }
                                            </TableCell>
                                            <TableCell className={classes.smallCell}>
                                                {
                                                    index === 0 && copied ?
                                                    <Typography
                                                        className={classes.copiedText}
                                                        color="primary">COPIED!</Typography>
                                                    :
                                                    index === 0 && token ?
                                                    <ContentCopyIcon
                                                        className={classes.modalIcon}
                                                        color={"primary"}
                                                        fontSize="small"
                                                        onClick={() => handleCopy(token)}
                                                    />
                                                    :
                                                    confirmDeletion === key.access_key ?
                                                    <div className={classes.buttonContainer}>
                                                        <CheckIcon className={classes.modalIcon} color="success" fontSize={"small"} onClick={() => deleteAccessKey(key.access_key)}/>
                                                        <ClearIcon className={classes.modalIcon} color="error" fontSize={"small"} onClick={() => setConfirmDeletion("")}/>
                                                    </div>
                                                    :
                                                    <DeleteIcon color={"primary"} className={classes.modalIcon}
                                                                key={key.access_key}
                                                                onClick={() => setConfirmDeletion(key.access_key)}/>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )
                            })
                    }
                </TableBody>
                <TablePagination
                    component="div"
                    count={filteredKeys.length}
                    rowsPerPage={3}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPageOptions={[]}
                />
            </>
        )
    }

    const generateKeyForm = () => {
        return (
            <form onSubmit={handleSubmit(generateAccessKey)}>
                <List className={classes.modalFormWrapper}>
                    <ListItem className={classes.modalFormInput}>
                        <Typography className={classes.modalFormLabel}>Name*</Typography>
                        <TextField
                            key="name"
                            placeholder="es. Download 01"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                        />
                    </ListItem>
                    <ListItem className={classes.modalFormInput}>
                        <Typography className={classes.modalFormLabel}>Expiration date</Typography>
                        <DatePicker
                            format="DD-MM-YYYY"
                            sx={{width:"13em"}}
                            minDate={dayjs(new Date())}
                            maxDate={undefined}
                            onChange={(event) => event && setExpirationDate((event.toDate()))}
                            slotProps={{
                                actionBar: {
                                    actions: ['clear'],
                                }
                            }}
                        />
                    </ListItem>
                    <ListItem className={classes.modalFormButton}>
                        <Button type={"submit"} variant={"contained"} disabled={!name}>
                            GENERATE KEY
                        </Button>
                    </ListItem>
                </List>
            </form>
        )
    }

    const filteredKeys =
        accessKeys
            .filter((key) =>
                key.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                key.access_key.toLowerCase().includes(searchQuery.toLowerCase())
            )

    return (
        <DefaultModal
            title={"Access Keys"}
            handleCancel={() => {
                if (!keyGenerationView) {
                    modalCtx?.closeModal()
                } else {
                    setKeyGenerationView(false);
                    setName("");
                    setExpirationDate(undefined);
                }
            }}
            cancelLabel={!keyGenerationView ? "Back to profile" : "Back to list"}
            buttonStyles={{buttonVariant: "contained"}}
        >
            <Box>
                <Grid className={classes.modalContainer}>
                    {!keyGenerationView ?
                        <>
                            <Grid container className={classes.modalInnerContainer}>
                                <TextField
                                    label="Name or Access Key"
                                    variant="outlined"
                                    onChange={(event) => {
                                        setPage(0);
                                        setSearchQuery(event.target.value);
                                    }}
                                    placeholder="Search"
                                    value={searchQuery}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        setKeyGenerationView(true);
                                        setConfirmDeletion("")
                                    }}>
                                    <KeyIcon></KeyIcon>
                                    GENERATE KEY
                                </Button>
                            </Grid>
                            <Box className={classes.modalList}>
                                <Box className={classes.scrollableList}>
                                    {loading ?
                                        <CircularProgress/>
                                        :
                                        <>
                                            {generateKeyTable()}
                                        </>
                                    }
                                </Box>
                            </Box>
                        </>
                        :
                        <>
                            {generateKeyForm()}
                        </>
                    }
                </Grid>
            </Box>
        </DefaultModal>
    )
}
