import {createStyles, makeStyles} from "@mui/styles";

const NavbarStyles = makeStyles((theme: any) =>
    createStyles({
        wrapper: {
            maxWidth: "94vw",
            height: "4.4em !important",
            backgroundColor: theme.palette.secondary.main + "!important",
            borderBottom: "1px solid #0000001A",
            boxShadow: "0px 1px 7px -5px #000000 !important",
        },
        internalWrapper: {
            justifyContent: "space-between !important"
        },
        tab: {
            justifyContent: "center",
            alignItems: "center",
            width: "10em !important",
            cursor: "pointer !important",
            height: "4.4em"
        },
        selectedTab: {
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer !important",
            borderBottom: "0.45em solid " +  theme.palette.primary.main + " !important",
            backgroundColor: theme.palette.secondary.hover + "!important"
        },
        tabTitle: {
            alignSelf: "center",
            fontSize: "0.95rem !important",
            fontWeight: 500 + "!important",
            color: theme.palette.text.primary
        },
        selectedTabTitle: {
            alignSelf: "center",
            fontSize: "0.95rem !important",
            fontWeight: 600 + "!important",
            color: theme.palette.text.primary
        },
        profileTabTitle: {
            color: theme.palette.primary.main
        },
        profileTabSubtitle: {
            color: theme.palette.primary.main + "!important"
        },
        profileTabContent: {
            justifyContent: "flex-start",
            width: "70%",
        },
        profileTab: {
            width: "fit-content !important",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer !important",
            padding: "1em",
            backgroundColor: theme.palette.secondary.hover + "!important"
        },
        exitTab: {
            backgroundColor: theme.palette.primary.main,
            height: "4.4em !important",
            width: "4.4em !important",
            alignItems: "center",
            justifyContent: "center"
        },
        exitTabIcon: {
            fontSize: "2.7rem !important",
            cursor: "pointer !important"
        }
}));

export {NavbarStyles};
