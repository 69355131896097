import {
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid, IconButton, InputLabel,
    ListItem,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, {ReactElement, useEffect, useRef, useState} from "react";
import {SourceFileResource} from "../../../../resources/clinicalData/sourceFile/sourceFileResource";
import {Controller, FieldValues, SubmitHandler, useForm} from "react-hook-form";
import {SourceFileDataProvider} from "../../../../resources/clinicalData/sourceFile/sourceFileDataProvider";
import {StepView} from "../../../../lib/views/other/step/step";
import {ClinicalStudiesDataProvider} from "../../../../resources/appManager/clinicalStudies/clinicalStudiesDataProvider";
import {ClinicalStudiesResource} from "../../../../resources/appManager/clinicalStudies/clinicalStudyResource";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import {SourceFilePreloadView} from "../preload/sourceFilePreloadView";
import {useModalContext} from "../../../../lib/context/ModalContext";
import {useNavigate} from "react-router-dom";
import {DefaultModal} from "../../../../lib/components/modal/defaultModal";
import {SourceFileUploadStyles} from "./uploadStyles";
import {ClinicalStudy} from "../../../../resources/appManager/clinicalStudies/clinicalStudy";
import CancelIcon from "@mui/icons-material/Cancel";
import {FileRow} from "../../../preload";

export interface SelectedColumn {
    name: string,
    index: number,
    id?: string,
    data_type?: string
}

export enum FileType {
    MAIN_CLINICAL = "mainClinical",
    OTHER = "other"
}

interface SourceFileMetadata {
    clinicalStudyId: string;
    fileName: string;
    fileType: FileType;

}

export function SourceFileUploadView(): ReactElement {
    const classes = SourceFileUploadStyles();

    const navigate = useNavigate();

    const fileInputRef = useRef<HTMLInputElement>(null)

    const [uploading, setUploading] = useState<boolean>(false);

    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [fileData, setFileData] = useState<FileRow[]>()
    const [pkIndex, setPkIndex] = useState<number>()
    const [selectedColumns, setSelectedColumns] = useState<SelectedColumn[]>([])
    const [fileMetadata, setFileMetadata] = useState<SourceFileMetadata>()
    const {trigger, getValues, control, formState} =
        useForm<SourceFileMetadata>({
            mode: "all"
        });

    const [clinicalStudies, setClinicalStudies] = useState<ClinicalStudy[]>([])

    const [loaded, setLoaded] = useState<boolean>(false)

    const modalContext = useModalContext();

    const sourceFileDataProvider = (SourceFileResource.dataProvider as unknown as SourceFileDataProvider);
    const clinicalStudiesDataProvider = (ClinicalStudiesResource.dataProvider as unknown as ClinicalStudiesDataProvider)


    useEffect(() => {
        Promise.all([
            clinicalStudiesDataProvider.pagedList().then(c => {
                setClinicalStudies(c.data)
            })
        ]).then(() => {
            setLoaded(true)
        })


    }, [])

    useEffect(() => {
        if (selectedFile) {
            preloadFile(selectedFile)
            trigger()
        }
    }, [selectedFile])

    const preloadFile = (file: File) => {
        const blob = new Blob([file], {type: "multipart/form-data"});
        const formData = new FormData();
        formData.append("document", blob, file.name);

        sourceFileDataProvider.postPreload(formData).then((data) => {
            setFileData(data)
        })
    }

    const onSubmit = (data: SourceFileMetadata) => {
        setFileMetadata(data)
    }

    const uploadFile: SubmitHandler<FieldValues> = () => {
        if (!fileMetadata) {
            return;
        }
        setUploading(true);

        if (!selectedFile || !fileData) return;
        const blob = new Blob([selectedFile], {type: "multipart/form-data"})

        const formData = new FormData();
        formData.append("fileType", fileMetadata.fileType);
        formData.append("fileName", fileMetadata.fileName);
        formData.append("primaryKeyColumnIndex", pkIndex?.toString()!);

        formData.append("columns", JSON.stringify(selectedColumns))
        formData.append("document", blob, selectedFile?.name)

        setTimeout(() => {
            clinicalStudiesDataProvider.upload(fileMetadata.clinicalStudyId, formData).then((r) => {
                setUploading(false)
                modalContext?.openModal({
                    component: <DefaultModal title={r.error !== null ? "ATTENTION!" : "Upload status"}
                                             description={r.error !== null ?
                                                 `Error: ${r.error}. The file ${fileMetadata.fileName} is uploaded with status ERROR.` :
                                                 `File ${fileMetadata.fileName} uploaded successfully`}
                                             confirmLabel={"Back to files"}
                                             handleConfirm={() => {
                                                 navigate(-1)
                                                 return modalContext?.closeModal()
                                             }}
                    />
                })
            }).catch((e) => {
                setUploading(false)
                modalContext?.openModal({
                    component: <DefaultModal title={"ATTENTION!"}
                                             description={`Unexpected error: ${e.response.data.msg}`}
                                             confirmLabel={"Back to files"}
                                             handleConfirm={() => {
                                                 navigate(-1)
                                                 return modalContext?.closeModal()
                                             }}
                    />
                })
            })
        }, 1000);
    }

    const isDisabled = () => {
        return (!selectedFile || !formState.isValid)
    }

    function handlePKSelect(e: any) {
        setPkIndex(e.target.value);
    }

    function handleColumnSelection(cols: SelectedColumn[]) {
        setSelectedColumns(cols)
    }

    if (!loaded) {
        return <CircularProgress/>
    }

    return (
        <StepView
            title={<Typography variant={"pageTitlePrefix"}>{"Source files"} </Typography>}
            steps={[
                {
                    title: <Typography variant={"pageTitle"} className={classes.title}>/ File metadata</Typography>,
                    description: "Please, fill in the data of the file you want to upload.",
                    button: {
                        text: "NEXT",
                        isDisabled: isDisabled(),
                        isProcessing: false,
                        onClick: () => {
                            onSubmit(getValues())
                        }
                    },
                    component: <>
                        <form>
                            <Grid container className={classes.formContainer}>
                                <Grid item md={6}>
                                    <Grid container direction={"row"} justifyContent={"space-between"}>
                                        <Grid item marginLeft={"1em"} display={"flex"} alignItems={"center"}>
                                            <Typography variant={"dataTitle"}>
                                                {"File name*"}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name={"fileName"}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "file name is required"
                                                    }
                                                }}
                                                render={({field, fieldState}) => {
                                                    return (
                                                        <Grid item>
                                                            <FormControl>
                                                                <TextField
                                                                    {...field}
                                                                    className={classes.input}
                                                                    error={fieldState.isTouched && fieldState.invalid}
                                                                />
                                                                {
                                                                    fieldState.isTouched && fieldState.invalid ?
                                                                        <FormHelperText
                                                                            error={true}
                                                                        >{fieldState.error?.message}</FormHelperText>
                                                                        : null
                                                                }
                                                            </FormControl>
                                                        </Grid>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={5.5}>
                                    <Grid container direction={"row"} justifyContent={"space-between"}>
                                        <Grid item marginLeft={"1em"} display={"flex"} alignItems={"center"}>
                                            <Typography variant={"dataTitle"}>
                                                {"File type*"}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name={"fileType"}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "File type is required"
                                                    }
                                                }}
                                                render={({field}) => {
                                                    return (
                                                        <FormControl>
                                                            <InputLabel id="cs-label">Select...</InputLabel>
                                                            <Select
                                                                {...field}
                                                                className={classes.input}
                                                                labelId={"cs-label"}
                                                                label={"Select..."}
                                                            >
                                                                <MenuItem value={FileType.MAIN_CLINICAL.valueOf()}>
                                                                    Main Clinical
                                                                </MenuItem>
                                                                <MenuItem value={FileType.OTHER.valueOf()}>
                                                                    Other
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    )
                                                }}
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item md={6}>
                                    <Grid container direction={"row"} justifyContent={"space-between"}>
                                        <Grid item marginLeft={"1em"} display={"flex"} alignItems={"center"}>
                                            <Typography variant={"dataTitle"}>
                                                {"Clinical Study*"}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Controller
                                                name={"clinicalStudyId"}
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "Clinical study is required"
                                                    }
                                                }}
                                                render={({field, fieldState}) => {
                                                    return (
                                                        <Grid item>
                                                            <Grid item>
                                                                <FormControl>
                                                                    <InputLabel id="cs-label">Select...</InputLabel>
                                                                    <Select
                                                                        className={classes.input}
                                                                        labelId={"cs-label"}
                                                                        label={"Select..."}
                                                                        {...field}
                                                                    >
                                                                        {
                                                                            clinicalStudies.map(c => {
                                                                                return <MenuItem
                                                                                    key={c.id}
                                                                                    value={c.id}
                                                                                >
                                                                                    {c.name}
                                                                                </MenuItem>
                                                                            })
                                                                        }

                                                                    </Select>
                                                                    {
                                                                        fieldState.isTouched && fieldState.invalid ?
                                                                            <FormHelperText
                                                                                error={true}
                                                                            >{fieldState.error?.message}</FormHelperText>
                                                                            : null
                                                                    }
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={5.5}>

                                </Grid>
                                <Grid item md={12} sx={{
                                    backgroundColor: "primary.light",
                                    borderRadius: "0.5em",
                                    display: "flex",
                                    alignItems: "center",
                                    pl: 2
                                }}>
                                    <Grid container>
                                        <Grid item md={3}>
                                            <Button variant={"contained"}>
                                                <FolderOpenIcon/>
                                                <label className={classes.fileSelectButton}
                                                       htmlFor="fileInput">&nbsp; Select file
                                                </label>
                                                <input id="fileInput"
                                                       onChange={(ev) => {
                                                           if (ev.target.files !== null && ev.target.files.length > 0) {
                                                               setSelectedFile(ev.target.files[0])
                                                           }
                                                       }}
                                                       hidden
                                                       ref={fileInputRef}
                                                       type="file"
                                                       accept={".csv, .xls, .xlsx"}
                                                />
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{display: "flex", alignItems: "center"}}>
                                            {
                                                selectedFile ?
                                                    <>
                                                        <IconButton onClick={() => {
                                                            setSelectedFile(undefined)
                                                            if (fileInputRef.current) {
                                                                fileInputRef.current.files = null
                                                                fileInputRef.current.value = ""
                                                            }
                                                        }}>
                                                            <CancelIcon/>
                                                        </IconButton>
                                                        <Typography variant={"body1"}>
                                                            {selectedFile.name}
                                                        </Typography>
                                                    </>
                                                    : null
                                            }
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </form>
                    </>
                },
                {
                    title: <Typography variant={"pageTitle"}>/ Patient ID</Typography>,
                    description: `To complete the upload of ${fileMetadata?.fileName}`,
                    button: {
                        text: "NEXT",
                        isDisabled: pkIndex === undefined,
                        isProcessing: false
                    },
                    component: <>
                        <form autoComplete="off">
                            <ListItem className={classes.keyValueSelect}>
                                <Typography variant={"dataTitle"} className={classes.keyValueSelectLabel}>
                                    Select Patient ID
                                </Typography>
                                <Select fullWidth onChange={handlePKSelect}>
                                    {
                                        fileData &&
                                        fileData[0].Cells.map((cell, index) => {
                                            return (
                                                <MenuItem value={index}>{cell.name}</MenuItem>
                                            )
                                        })}
                                </Select>
                            </ListItem>
                        </form>
                    </>
                },
                {
                    title: <Typography variant={"pageTitle"}>/ Upload source file</Typography>,
                    description: `To complete the upload of ${fileMetadata?.fileName}`,
                    button: {
                        text: "Complete Upload",
                        isDisabled: isDisabled(),
                        isProcessing: uploading,
                        onClick: uploadFile
                    },
                    component: <SourceFilePreloadView
                        data={fileData!}
                        isMainClinical={fileMetadata?.fileType === "mainClinical"}
                        primaryKey={pkIndex ?? 0}
                        clinicalStudyId={fileMetadata?.clinicalStudyId || ""}
                        columnSelectionCallback={handleColumnSelection}/>
                }

            ]
            }
        />
    )
}
