import {Laboratory} from "../../appManager/laboratory/laboratory";
import {ClinicalStudy} from "../../appManager/clinicalStudies/clinicalStudy";

export enum LabTestStatus {
    ASSOCIATED = "associated",
    NOT_ASSOCIATED = "notAssociated",
    CANCELED = "canceled",
    UPLOADING = "uploading"
}

export interface LabTest {
    id: string;
    name: string;
    laboratory: Laboratory;
    laboratory_id: string;
    laboratory_name: string;
    patient_id: string;
    clinical_study: ClinicalStudy;
    clinical_study_id: string;
    clinical_study_name: string;
    data: LabTestMetadata[];
    status: LabTestStatus;
    size: number;
    temporary_link: string;
}

export interface LabTestMetadata {
    id: string
    created_at: string
    metadata: [
            {
                data_type: string,
                name: string,
                value: string
            }
        ]
    patient_id: string
    source_file_id: string
    test_file_id: string
    updated_at: string
}

export interface LabTestFilter {
    q: string;
    clinical_study_id: string;
    laboratory_id: string;
    status: string;
}
