import {ResourceConfig} from "../../../lib/types/resource";
import {SimpleTextMeta} from "../../../lib/meta/simpleText/simpleText";
import React from "react";
import {SourceFile, SourceFileFilter, SourceFileStatus, SourceType} from "./sourceFile";
import {SimpleSelectMeta} from "../../../lib/meta/select/simpleSelect";
import {SourceFileDataProvider} from "./sourceFileDataProvider";
import {IDMeta} from "../../../lib/meta/id/id";
import {sourceFileFilter} from "./sourceFileFIlter";
import {StepAction} from "../../../lib/actions/stepAction";
import {ActionType} from "../../../lib/types/action";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {SourceFileUploadView} from "../../../components/clinicalData/sourceFile/upload/upload";
import {ClinicalStudiesResource} from "../../appManager/clinicalStudies/clinicalStudyResource";
import {DefaultShowTabView} from "../../../lib/views/crud/show/defaultTab";
import {DateMeta} from "../../../lib/meta/date/date";
import {NoArgsAction} from "../../../lib/actions/noArgsAction";
import {StateMeta} from "../../../lib/meta/state/state";
import {OneToMany} from "../../../lib/meta/oneToMany/oneToMany";
import {Audit, AuditFilter} from "../../appManager/audit/audit";
import {AuditResource} from "../../appManager/audit/auditResource";
import {auditFilter} from "../../appManager/audit/auditFilter";
import {ClinicalStudy} from "../../appManager/clinicalStudies/clinicalStudy";
import {createTheme, Typography} from "@mui/material";
import {ButtonsVariant, CustomTheme} from "../../../theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import {Upload} from "@mui/icons-material";
import {LongTextMeta} from "../../../lib/meta/longText/longText";
import {SingleArgsAction} from "../../../lib/actions/singleArgsAction";

interface ConfirmAction {
    text: string
}

const theme = createTheme(CustomTheme(), ButtonsVariant());

export const SourceFileResource: ResourceConfig<SourceFile, SourceFileFilter> = {
    id: "sourceFiles",
    label: "Source Files",
    customRoute: {
        path: "upload",
        component: <SourceFileUploadView/>
    },
    actions: [
        new StepAction<{}, SourceFile>({
            id: "upload",
            label: "Upload",
            actionType: ActionType.Step,
            icon: <FileUploadIcon fontSize={"medium"}/>,
        }),
        new SingleArgsAction<SourceFile, SourceFile>({
            id: "delete",
            label: "Delete",
            hidden: (data) => {
                return !data
            },
            handler: (data, dataProvider, _authProvider, feedbackCtx, formData) => {
                const sfDataProvider = dataProvider as unknown as SourceFileDataProvider;
                const sfData = data as SourceFile;
                if (sfData.status === SourceFileStatus.CANCELED || sfData.status === SourceFileStatus.ERROR) {
                    return sfDataProvider
                        .hardDelete("id" in data ? data.id : "", true, formData)
                        .then(r => {
                            feedbackCtx?.openBottomSuccessSnackbar("Source file successfully deleted.")
                            return r;
                        })
                } else {
                    return dataProvider &&
                        dataProvider
                            .delete("id" in data ? data.id : "")
                            .then(r => {
                                feedbackCtx?.openBottomSuccessSnackbar("Source file successfully deleted.")
                                return r
                            })
                }
            },
            choiceAction: {
                confirmLabel: "Delete",
                cancelLabel: "Cancel",
                description: "",
            },
            modalDescription: (data) => {
                const sfData = data as SourceFile;
                return (sfData.status === SourceFileStatus.CANCELED ? sfData.source_type === SourceType.MAIN ?
                    "Are you sure you want to PERMANENTLY delete this file of type MAIN CLINICAL? " +
                    "\nAll the other files linked to the same clinical study will also be removed." :
                    "Are you sure you want to delete this file?" :
                    "Are you sure you want to cancel this file? You will still be able to download it."
                )
            },
            actionStyle: {
                buttonVariant: 'outlined',
                buttonColor: 'error'
            },
            actionType: ActionType.Single,
            fields: [
                {id: "id", label: "Why do you want to delete this source file?*",
                    meta: new LongTextMeta<any>(),
                    hidden: (data?: SourceFile) => data?.status !== SourceFileStatus.CANCELED && data?.status !== SourceFileStatus.ERROR,
                    validationOptions: {
                        required: {
                            value: true,
                            message: "This field is required."
                        }
                    }}
            ],
        }),
        new NoArgsAction<ConfirmAction, SourceFile>({
            id: "download",
            label: "Download",
            hidden: (data) => {
                return !data || (data as SourceFile).status === SourceFileStatus.ERROR
            },
            handler: (data, dataProvider, _authProvider?, feedbackCtx?) => {
                const sfData = data as SourceFile;
                const sfDataProvider = (dataProvider as unknown as SourceFileDataProvider);
                return sfDataProvider &&
                    sfDataProvider
                        .download(sfData.id)
                        .then(r => {
                            const blob = new Blob([r], {type: "application/octet-stream"});
                            const href = URL.createObjectURL(blob);
                            const download = Object.assign(document.createElement('a'), {
                                href: href,
                                download: sfData.name
                            });
                            document.body.append(download);
                            download.click();
                            download.remove();
                            URL.revokeObjectURL(href);
                            feedbackCtx?.openBottomSuccessSnackbar("Download started successfully.")
                            return r
                        })
            },
            choiceAction: {
                confirmLabel: "Download",
                cancelLabel: "Cancel",
                description: "",
            },
            modalDescription: () => {
                return "Do you want to download this file?"
            },
            actionStyle: {
                buttonVariant: 'outlined',
                buttonColor: 'primary'
            },
            actionType: ActionType.Single
        })
    ],
    dataProvider: new SourceFileDataProvider(),
    filter: sourceFileFilter,
    fields: [
        {
            id: "status",
            label: "State",
            meta: new StateMeta({stateBadge: (data: SourceFile) => {
                    return (
                        <div>
                            {
                                data.status === SourceFileStatus.PROCESSED ?
                                    <CheckCircleIcon color={"success"} fontSize={"large"}/>
                                :
                                data.status === SourceFileStatus.CANCELED ?
                                    <CancelIcon color={"error"} fontSize={"large"}/>
                                :
                                data.status === SourceFileStatus.ERROR ?
                                    <WarningIcon color={"warning"} fontSize={"large"}/> :
                                        data.status === "uploading" ? <></> :
                                data.status === SourceFileStatus.UPLOADING ?
                                    <Upload color={"primary"} fontSize={"large"}/> : <></>
                            }
                        </div>
                    )
                }})
        },
        {
            id: "tag_name",
            label: "Name",
            meta: new SimpleTextMeta({})
        },
        {
            id: "name",
            label: "File Name",
            meta: new SimpleTextMeta({})
        },
        {
            id: "id",
            label: "ID",
            meta: new IDMeta()
        },
        {
            id: "clinical_study",
            label: "Clinical Study",
            meta: new SimpleSelectMeta({
                resource: ClinicalStudiesResource,
                valuer: (study: ClinicalStudy) => (study as unknown as SourceFile).clinical_study.name.toString()
            })
        },
        {
            id: "log",
            label: "Log",
            meta: new OneToMany<SourceFile, Audit, AuditFilter>({
                resource: AuditResource,
                defaultFilter: (data) => {return {object_id: data.id, object: "SOURCE_FILE"} as AuditFilter},
                otherFilters: auditFilter.filters.filter((f) => f.field.id !== "object_id" && f.field.id !== "object"),
                fields: [
                    {
                        id: "created_at",
                        label: "Date",
                        meta: new DateMeta({})
                    },
                    {
                        id: "action",
                        label: "Action",
                        meta: new SimpleTextMeta({})
                    },
                    {
                        id: "user_id",
                        label: "User ID",
                        meta: new SimpleTextMeta({})
                    },
                    {
                        id: "object",
                        label: "Target",
                        meta: new SimpleTextMeta({})
                    }],
                pageSize: 10
            })
        },
        {
            id: "source_type",
            label: "File Type",
            meta: new SimpleTextMeta({})
        },
        {
            id: "created_at",
            label: "Upload Date",
            meta: new DateMeta({}),
        },
        {
            id: "error",
            label: "Error",
            meta: new SimpleTextMeta({})
        }
    ],
    tabPosition: "left",
    list: {
        id: "source_file",
        pageSize: 10,
        fields: [{id: "status"}, {id: "tag_name"}, {id: "source_type"}, {id: "created_at"}, {id: "clinical_study"}]
    },
    show: {
        component: <DefaultShowTabView id={"source_file"}
                                       tabs={[
                                           {
                                               label: "Data",
                                               fields: ["tag_name", "source_type", "created_at", "clinical_study", "error"]
                                           },
                                           {
                                               label: "Audit",
                                               fields: ["log"]
                                           }
                                       ]}
                                       getTitle={(data?: SourceFile) => {
                                           const badgeValue = data?.status;
                                           return (
                                               <>
                                                   <Typography variant={"pageTitle"}
                                                               style={{
                                                                   width: "fit-content",
                                                                   maxWidth: "15em",
                                                                   whiteSpace: "nowrap",
                                                                   overflow: "hidden",
                                                                   textOverflow: "ellipsis"
                                                               }}
                                                   >/ {(data as SourceFile)?.tag_name}</Typography>
                                                   <Typography
                                                       variant={"stateBadge"}
                                                       style={{
                                                           color: badgeValue === SourceFileStatus.PROCESSED ? theme.palette.success.main :
                                                                  badgeValue === SourceFileStatus.ERROR ? theme.palette.warning.main :
                                                                  badgeValue === SourceFileStatus.CANCELED ? theme.palette.error.main :
                                                                  badgeValue === SourceFileStatus.UPLOADING ? theme.palette.primary.main : ""
                                                           ,
                                                           marginLeft: "1rem"
                                                       }}>{badgeValue?.toUpperCase()}
                                                   </Typography>
                                               </>
                                           )
                                       }}
                                       previousPage={-1}
                    />,
        fields: [{id: "tag_name"}, {id: "source_type"}, {id: "created_at"}, {id: "clinical_study"}]
    },
    description: "List of the source files containing clinical data for each clinical study.",
    isDeletable: () => true,
    primaryKey: "id",
    groupId: "clinical"
}
