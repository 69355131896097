import {Grid, Typography} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { ReactElement } from "react";
import { Control, FieldValues, Path, RegisterOptions, useController } from "react-hook-form";
import { Field } from "../../types/field";
import { ListMeta, MetaField } from "../../types/meta";

interface DateProps {
    label?: string;
    data?: Date;
}
function DateShow({label, data}: DateProps): ReactElement {
    const date = new Date(data?.toString()!);
    return (
        <>
             <Grid item paddingBottom={"1em"}>
                <Typography variant={"dataTitle"}>
                    {label}
                </Typography>
             </Grid>
             <Grid item paddingBottom={"1em"}>
                 <Typography variant={"data"}>
                    {!data? "--" : [date.getDate(), date.getMonth() + 1, date.getFullYear()]
                                        .map(n => n < 10 ? `0${n}` : `${n}`)
                                        .join('/')+", "+
                                    [date.getHours(), date.getMinutes(), date.getSeconds()]
                                        .map(n => n < 10 ? `0${n}` : `${n}`)
                                        .join(":")
                    }
                 </Typography>
             </Grid>
        </>
    )
}

function DateForm<T>(
    formControl: Control,
    name: Path<FieldValues>,
    data?: T,
    validationOptions?: RegisterOptions,
    minDate?: Date,
    maxDate?: Date,
    valuer?: (data: T) => string
): ReactElement {

    const { field, fieldState } = useController({
        name: name,
        control: formControl,
        rules: validationOptions
    });

    return (
        <DatePicker
            {...field}
            format="DD-MM-YYYY"
            sx={{width:"20em"}}
            defaultValue={(data && data[field.name as keyof T] !== null) ? valuer && valuer(data) : undefined}
            minDate={minDate? dayjs(minDate) : undefined}
            maxDate={maxDate? dayjs(maxDate) : undefined}
            slotProps={{
                textField: {
                    helperText: fieldState.error?.message,
                    placeholder: data && data[field.name as keyof T] ? valuer && valuer(data) : "DD/MM/YYYY",
                },
                actionBar: {
                    actions: ['clear'],
                }
            }}
        />
    );
}

interface DateMetaProps<T> {
    minDate?: Date,
    maxDate?: Date,
    valuer? : (data: T) => string
}


export class DateMeta<T> implements MetaField<T> {
    minDate?: Date;
    maxDate?: Date;
    valuer? : (data: T) => string;
    constructor(props: DateMetaProps<T>) {
        this.minDate = props.minDate;
        this.maxDate = props.maxDate;
        this.valuer = props.valuer
    }

    getListMeta(): ListMeta {
        return ({
            type: 'string',
            value: (arg: Date) => {
                const date = new Date(arg)
                return [date.getDate(), date.getMonth()+1, date.getFullYear()].join('/');
            }
        })
    };

    getListComponent(data: T | undefined, field: Field<T>) {
        const d =  data && data[field.id]
        const date = new Date(d!.toString())
        return () => {
            return <Typography noWrap variant={"body1"}>
                {[date.getDate(), date.getMonth() + 1, date.getFullYear()]
                        .map(n => n < 10 ? `0${n}` : `${n}`)
                        .join('/')+", "+
                    [date.getHours(), date.getMinutes(), date.getSeconds()]
                        .map(n => n < 10 ? `0${n}` : `${n}`)
                        .join(":")
                }
            </Typography>
        }
    }

    getFormComponent(
        control: Control,
        name: Path<FieldValues>,
        field: Field<T>,
        data?: T): ReactElement {
        return DateForm<T>(control, name, data, field.validationOptions, this.minDate, this.maxDate, this.valuer);
    }

    getShowComponent(data: T | undefined, field: Field<T>){
        return () => {
            return DateShow({label: field.label, data: data ? data[field.id] as Date : undefined})
        }
    }
}
