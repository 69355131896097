import React, {Fragment, ReactElement, useEffect, useState} from "react";
import {ProfileStyles} from "./profileStyles";
import {useNavigate} from "react-router-dom";
import {Button, Divider, Grid, ListItem, TextField, Typography} from "@mui/material";
import {useResourceContext} from "../../lib/context/ResourceContext";
import {Field} from "../../lib/types/field";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import {Profile} from "../../resources/profile/profile";
import {useAuthenticationContext} from "../../lib/context/AuthenticationContext";
import axios, {AxiosError} from "axios";
import {BASE_URL} from "../../App";
import {useFeedbackContext} from "../../lib/context/FeedbackContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyIcon from '@mui/icons-material/Key';
import {useModalContext} from "../../lib/context/ModalContext";
import {ApiKeysModal} from "./apiKeys/apiKeysModal";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {ErrorDTO} from "../../hoc/axiosHandlerProvider/AxiosHandlerProvider";

interface ProfileDetailProps {
    id: string;
    editMode: boolean;
    fields: Field<Profile>[];
}

export function ProfileDetailView({editMode, ...props}: ProfileDetailProps): ReactElement {
    const classes = ProfileStyles();
    const resourceContext = useResourceContext<Profile, any>()
    const [data, setData] = useState<Profile | undefined>(undefined);
    const [newData, setNewData] = useState<Profile | undefined>(undefined);

    const [isFormValid, setIsFormValid] = useState(true);

    const navigate = useNavigate();

    const resource = resourceContext.getConfig();
    const authCtx = useAuthenticationContext();
    const feedbackContext = useFeedbackContext();
    const modalCtx = useModalContext();

    const feedbackCtx = useFeedbackContext();


    useEffect(() => {
        setData(undefined)
        initData();
    }, [resource])

    useEffect(() => {
        setIsFormValid(!(newData && Object.values(newData).some((value) => value === "")));
    }, [newData]);


    function initData() {
        authCtx.getProvider().getAuthHeaders().then(headers => {
            axios.get(`${BASE_URL}/users/profile`, {headers: headers}).then(d => {
                setData(d.data as Profile)
                setNewData(d.data as Profile)
            })
        })
    }

    function mergeField(field: Field<Profile>): Field<Profile> {
        const propsField = props.fields?.find(f => f.id === field.id);
        if (!propsField) return field;
        if (propsField.validationOptions) field.validationOptions = propsField.validationOptions;
        if (propsField.meta) field.meta = propsField.meta;
        if (propsField.label) field.label = propsField.label;
        return field;
    }

    const saveData = () => {
        authCtx.getProvider().getAuthHeaders().then(headers => {
            axios.put(`${BASE_URL}/users/update`, newData,{headers: headers}).then(() => {
                feedbackContext.openBottomSuccessSnackbar("Profilo updated successfully.")
                navigate(-1)
            }).catch((err) => {
                const axiosError = err as AxiosError<ErrorDTO, unknown>;
                feedbackCtx.openBottomErrorSnackbar(`Error: ${axiosError.response?.data.msg}`)
            })
        })
    }

    const manageApiKeys = () => {
        modalCtx?.openModal({component: <ApiKeysModal></ApiKeysModal>})
    }

    return (
        <Grid container className={classes.mainContainer}>
            <Grid container direction={"column"}>
                <Grid item>
                    <Grid container direction={"row"} className={classes.titleContainer}>
                        <div className={classes.title} style={{ display: 'flex', alignItems: 'center' }}>
                            <AccountCircleIcon className={classes.icon}/>
                            <Typography variant={"pageTitle"}>{resource?.label} </Typography>
                        </div>
                        <Grid container className={classes.actionsContainer}>
                            {
                                editMode ?
                                    <>
                                        <Button
                                            variant="outlined"
                                            disabled={!isFormValid}
                                            onClick={() => {
                                                saveData()
                                            }}>
                                            Save
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color={"error"}
                                            onClick={() => {
                                                navigate(-1);
                                            }}>
                                            Discard
                                        </Button>
                                    </>
                                    :
                                    <>
                                    <Button
                                        variant="outlined"
                                        onClick={() => navigate("/userGuide")}
                                    >
                                        <MenuBookIcon style={{marginRight: "0.5rem"}}/>
                                        USER GUIDE
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={manageApiKeys}
                                    >
                                        <KeyIcon style={{marginRight: "0.5rem"}}/>
                                        ACCESS KEYS
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={() => navigate('edit')}
                                    >
                                        <BorderColorIcon style={{marginRight: "0.5rem"}}/>
                                        Edit
                                    </Button>
                                    </>
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.dataContainer}>
                <Grid container direction={'column'}>
                    {
                        resource.fields
                            .filter(f => props.fields.find(ff => ff.id === f.id))
                            .map((f, index) => {
                                const field = mergeField(f);
                                return (
                                    <Fragment key={index}>
                                        <ListItem
                                            className={classes.showListItem}>
                                            {
                                                editMode ?
                                                    <>
                                                        <Grid item>
                                                            <Typography variant={"dataTitle"}>
                                                                {field.label ?? ""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <TextField
                                                                id={field.id}
                                                                value={newData ? newData[field.id] : ""}
                                                                required={true}
                                                                onChange={(ev) => {
                                                                    if(!newData){
                                                                        return
                                                                    }
                                                                    setNewData({...newData, [field.id]: ev.target.value})
                                                                }}

                                                            />
                                                        </Grid>
                                                    </>
                                                    :
                                                    <>
                                                        {field.meta?.getShowComponent(data, field)({})}
                                                    </>
                                            }
                                        </ListItem>
                                        <Divider/>
                                    </Fragment>
                                )
                            })
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}
