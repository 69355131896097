import {
    Autocomplete,
    FormControlLabel,
    FormGroup,
    Grid, Icon, IconButton,
    List,
    ListItem,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import React, {ReactElement, useEffect, useState} from "react";
import Card from '@mui/material/Card';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {SourceFilePreloadStyles} from "./preloadStyles";
import {Column} from "../../../../resources/appManager/clinicalStudies/clinicalStudiesDataProvider";
import {ClinicalStudiesResource} from "../../../../resources/appManager/clinicalStudies/clinicalStudyResource";
import {ClinicalStudiesDataProvider} from "../../../../resources/appManager/clinicalStudies/clinicalStudiesDataProvider";
import {SelectedColumn} from "../upload/upload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {CardData, FileRow} from "../../../preload";

export interface PreloadProps {
    data: FileRow[]
    clinicalStudyId: string;
    primaryKey: number;
    columnSelectionCallback: (data: SelectedColumn[]) => void
    isMainClinical: boolean
}

export function SourceFilePreloadView({data, ...props}: PreloadProps): ReactElement {
    const classes = SourceFilePreloadStyles();

    const clinicalStudiesDataProvider = (ClinicalStudiesResource.dataProvider as unknown as ClinicalStudiesDataProvider);

    const [clinicalStudiesColumns, setClinicalStudiesColumn] = useState<Column[]>([]);

    const [cardsData, setCardsData] = useState<CardData[]>([]);

    useEffect(() => {
        clinicalStudiesDataProvider.getColumns(props.clinicalStudyId).then((columns) => {
            setClinicalStudiesColumn(columns)
        })
        const newCardsData = [...cardsData]

        data.slice(0, 3).forEach(r => {
            r.Cells.filter(c => c.index !== props.primaryKey).forEach(
                (c, idx) => {
                    const alreadyExisting = idx < newCardsData.length
                    if (alreadyExisting) {
                        newCardsData[idx] = {
                            cells: [...newCardsData[idx].cells, c.value],
                            column_name: c.name,
                            data_type: c.dataType,
                            file_index: c.index,

                        }
                    } else {
                        newCardsData.push({
                            cells: [c.value],
                            column_name: c.name,
                            data_type: c.dataType,
                            file_index: c.index,
                        })
                    }

                }
            )
        })
        setCardsData(newCardsData)
    }, [])

    useEffect(() => {
        props.columnSelectionCallback(
            cardsData.map((c: CardData): SelectedColumn => {
                return {
                    data_type: c.data_type,
                    id: c.column_id === null ? undefined : c.column_id,
                    index: c.file_index,
                    name: c.column_name
                }
            })
        )
    }, [cardsData])

    return (
        <>
            <Grid container height={"30vw"} className={classes.mainContainer} flexWrap={'nowrap'}>
                <Grid container className={classes.innerContainer} flexDirection={"column"}>
                    {cardsData.map((c: CardData, index: number) => {
                        return (
                            <Card className={classes.card}>
                                <Grid container direction={"column"}>
                                    <Grid item className={classes.switch}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                <Switch
                                                    disabled={props.isMainClinical}
                                                    key={index}
                                                    checked={c.column_id === undefined}
                                                    onChange={(ev) => {
                                                        const newCardsData = [...cardsData]
                                                        newCardsData[index].column_id = ev.target.checked ?
                                                            undefined : null
                                                        setCardsData(newCardsData)
                                                    }}
                                                    color={"success"}
                                                />}
                                                label={"NEW"} labelPlacement={"start"}/>
                                        </FormGroup>
                                    </Grid>
                                    {
                                        c.column_id === undefined ?
                                            <Select
                                                className={classes.select}
                                                key={index}
                                                displayEmpty={true}
                                                value={c.data_type}
                                                onChange={(ev) => {
                                                    const newCardsData = [...cardsData]
                                                    newCardsData[index].data_type = ev.target.value
                                                    setCardsData(newCardsData)
                                                }}

                                            >
                                                <MenuItem value={"string"}>String</MenuItem>
                                                <MenuItem value={"number"}>Number</MenuItem>
                                            </Select>
                                            :
                                            <Autocomplete
                                                selectOnFocus
                                                disableClearable
                                                className={classes.select}
                                                onChange={(ev, value) => {
                                                    const newCardsData = [...cardsData]
                                                    newCardsData[index].column_id = value.value
                                                    setCardsData(newCardsData)
                                                }}
                                                renderInput={(params) =>
                                                    <TextField {...params} label={"Select column..."}/>
                                                }
                                                options={clinicalStudiesColumns.map((col) => ({
                                                    label: col.name,
                                                    value: col.id
                                                }))}
                                            />
                                    }
                                    <List className={classes.columnsList}>
                                        {
                                            <ListItem className={classes.columnTitle}>
                                                {c.edited_name !== undefined ?
                                                    <TextField
                                                        onChange={(ev) => {
                                                            const newCardsData = [...cardsData]
                                                            newCardsData[index].edited_name = ev.target.value
                                                            setCardsData(newCardsData)
                                                        }}
                                                        value={c.edited_name}
                                                        placeholder={c.column_name}
                                                    />
                                                    :
                                                    <Typography variant={"smallButtonText"}
                                                                className={classes.columnTitleText}>
                                                        {
                                                            c.column_name
                                                        }
                                                    </Typography>
                                                }
                                                <Icon
                                                />
                                                <IconButton
                                                    onClick={() => {
                                                        const newCardsData = [...cardsData]
                                                        const eName = newCardsData[index].edited_name
                                                        const isEdited = eName !== undefined && eName !== ""
                                                        newCardsData[index].edited_name = eName === undefined ? "" : undefined
                                                        newCardsData[index].column_name = isEdited ? eName : newCardsData[index].column_name
                                                        setCardsData(newCardsData)

                                                    }}>
                                                    {
                                                        c.edited_name === undefined ?
                                                            <BorderColorIcon fontSize={"small"} color={"primary"}/>
                                                            :
                                                            <CheckCircleIcon fontSize={"small"} color={"primary"}/>
                                                    }

                                                </IconButton>
                                            </ListItem>
                                        }
                                        {c.cells.map((cell, idx) => {
                                            return (
                                                <>
                                                    <ListItem key={idx} style={{
                                                        justifyContent: "center",
                                                        backgroundColor: (idx % 2 === 0 ? "#F6F6F6" : "#DDDDDD")
                                                    }}>
                                                        <Typography variant={"gridData"}>{cell !== "" ? cell : "--"}</Typography>
                                                    </ListItem>
                                                </>
                                            )
                                        })
                                        }
                                    </List>
                                </Grid>
                            </Card>
                        )
                    })}
                </Grid>
            </Grid>
        </>
    )
}
