import {Typography} from "@mui/material";
import {DateMeta} from "../../../lib/meta/date/date";
import {IDMeta} from "../../../lib/meta/id/id";
import {SimpleTextMeta} from "../../../lib/meta/simpleText/simpleText";
import {ResourceConfig} from "../../../lib/types/resource";
import {BaseDataProvider} from "../../baseDataProvider";
import {Audit, AuditAction, AuditFilter, AuditObject} from "./audit";
import {auditFilter} from "./auditFilter";
import React from "react";
import {userInfoLink} from "./hyperlikConfigs/userInfo";
import {DefaultShowTabView} from "../../../lib/views/crud/show/defaultTab";

export const AuditResource: ResourceConfig<Audit, AuditFilter> = {
    id: "audits",
    label: "Audit",
    dataProvider: new BaseDataProvider<Audit, AuditFilter>("audit"),
    filter: auditFilter,
    actions: [],
    fields: [
        {
            id: "id",
            label: "ID",
            meta: new IDMeta()
        },
        {
            id: "created_at",
            label: "Created at",
            meta: new DateMeta({})
        },
        {
            id: "user_id",
            label: "User Id",
            meta: userInfoLink()
        },
        {
            id: "action",
            label: "Action",
            meta: new SimpleTextMeta({
                valuer: (data: Audit) => {
                    const actionValue = Object.entries(AuditAction).find(
                        ([key, value]) => key === data.action
                    );
                    if (!data) return "--";
                    return actionValue ? actionValue[1] : data.action ? data.action.valueOf() : data.toString();
                }
            })
        },
        {
            id: "object",
            label: "Target",
            meta: new SimpleTextMeta({
                valuer: (data: Audit) => {
                    const actionValue = Object.entries(AuditObject).find(
                        ([key, value]) => key === data.object
                    );
                    if (!data) return "--";
                    return actionValue ? actionValue[1] : data.object ? data.object.valueOf() : data.toString();
                }
            })
        },
        {
            id: "object_id",
            label: "Target ID",
            meta: new SimpleTextMeta({})
        },
        {
            id: "path",
            label: "Path",
            meta: new SimpleTextMeta({})
        },
        {
            id: "body",
            label: "Info",
            meta: new SimpleTextMeta({
                valuer: (data) => data? JSON.stringify(data).replace(/[{}"]/g, '') : "--"
            })
        },
    ],
    list: {
        id: "audit",
        pageSize: 20,
        fields:[{id:"action"},{id:"object"},{id:"object_id"},{id: "user_id"},{id:"created_at"}]
    },
    show: {
        component: <DefaultShowTabView
                        id={"audit"}
                        tabs={[{label: "info", fields: ["id", "user_id", "created_at", "action", "object", "object_id", "path", "body"]}]}
                        fields={[{id: "id"},{id: "user_id"},{id: "created_at"},{id: "action"},{id: "object"},{id:"object_id"}, {id: "path"}, {id: "body"}]}
                        getTitle={(data?:Audit) => {
                            return (
                                <>
                                    <Typography variant={"pageTitle"}
                                                style={{
                                                    width: "fit-content",
                                                    maxWidth: "15em",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis"
                                                }}
                                    >/ {data?.id}</Typography>
                                </>)
                        }}
                        previousPage={-1}
        />,
        fields: [{id: "id"},{id: "user_id"}, {id: "created_at"},{id: "action"},{id: "object"},{id:"object_id"}]
    },
    primaryKey: "id",
    description: "List of all the traceable actions performed on miWeb.",
    isDeletable: () => false,
    groupId: "appManager"
}



