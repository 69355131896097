import axios from "axios";
import React, {ReactElement, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {BASE_URL} from "../../../App";
import AuthLayout from "../../../layout/authLayout";
import {useAuthenticationContext} from "../../../lib/context/AuthenticationContext";
import {AuthFormHeader} from "../authFormHeader";
import {LoginForm, LoginFormInputs} from "./loginForm";
import MultiFactorAuthForm from "./mfa/mfaForm";
import {CustomAuthData} from "../../../auth/provider";
import {CircularProgress, Grid, TextField, Typography, useMediaQuery} from "@mui/material";
import QRCodeGenerator from "../register/qrCode";
import {useModalContext} from "../../../lib/context/ModalContext";
import {DefaultModal} from "../../../lib/components/modal/defaultModal";
import {useFeedbackContext} from "../../../lib/context/FeedbackContext";
import {useTheme} from "@mui/styles";
import {LayoutStyles} from "../../../layout/layoutStyles";


interface OtpData{
    otp_url:string;
    otp_secret:string;
}

export function Login() {
    const navigate = useNavigate();
    const authCtx = useAuthenticationContext<CustomAuthData>();
    const authCnfg = authCtx?.getConfig();
    const authProvider = authCnfg?.provider;
    const modalCtx = useModalContext()
    const [hasRefreshToken, setHasRefreshToken] = useState<boolean>(false);

    const [authenticated, setAuthenticated] = useState<boolean>(false);

    const theme = useTheme();
    const smallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const classes = LayoutStyles();

    const feedbackCtx = useFeedbackContext()

    const [isMfaEnabled, setIsMfaEnabled] = useState<boolean>();

    const [isContractSigned, setIsContractSigned] = useState<boolean>(false);

    const [mfaSecret, setMfaSecret] = useState<OtpData>()

    const userAuthenticated = () => {
        authCtx?.setAuthenticated(true);
    }

    useEffect(() => {
        let authData = authProvider?.getAuthData()
        if (!authData) return;
        if (authData.refreshToken) {
            if (authData.accessToken) {
                userAuthenticated()
                return;
            }
            setHasRefreshToken(true)
            getUserInfo()
        }
    }, [])

    const getUserInfo = () => {
        authProvider?.getAuthHeaders(false).then(headers => {
            axios.get(`${BASE_URL}/users/profile`, {headers: headers}).then(u => {
                const mfaEn = u.data.mfa_enabled && u.data.mfa_confirmed
                const signed = u.data.terms_and_conditions.terms && u.data.terms_and_conditions.explicit
                setIsMfaEnabled(mfaEn);
                setIsContractSigned(signed);
                if (!mfaEn) {
                    const otpSecr = sessionStorage.getItem("otpSecret")
                    if (otpSecr != null) {
                        setMfaSecret(JSON.parse(otpSecr) as OtpData)
                    } else {
                        axios.put(`${BASE_URL}/users/enable_mfa`, {}, {headers: headers}).then(d => {
                            sessionStorage.setItem("otpSecret", JSON.stringify(d.data))
                            setMfaSecret(d.data as OtpData)
                        })
                    }
                }
            })
        })

    }

    function postLogin(data: LoginFormInputs) {
        axios.post(`${BASE_URL}/auth/login`, {...data})
            .then((resp) => {
                setHasRefreshToken(true);
                authProvider.setAuthData(new CustomAuthData(null, resp.data.token))
                getUserInfo()
            })
            .catch((error) => {
                if (error.status === 401) {
                    return feedbackCtx?.openErrorSnackbar(`Error occurred during the login. The credentials are invalid or you ran out of login attempts.`)
                }
                if (error.status === 503) {
                    return feedbackCtx?.openErrorSnackbar(`Server error. Login unsuccessful.`)
                }

            });
    }

    function postOtp(otp: string) {
        axios.get(`${BASE_URL}/users/profile?check_otp=true`, {
            headers: {
                'X-Auth-Refresh': `Bearer ${authProvider.getAuthData().refreshToken}`,
                'X-Auth-Otp': otp
            }
        })
            .then((resp) => {
                if (resp.headers['x-auth-access']) {
                    let authData = authProvider?.getAuthData()
                    sessionStorage.removeItem("otpSecret")
                    authData?.setAccessToken(resp.headers['x-auth-access']);
                    authProvider.setAuthData(authData);
                    userAuthenticated();
                    setAuthenticated(true);
                    if (isContractSigned) {
                        navigate("/home/profile");
                    } else {
                        navigate("/privacy");
                    }
                }
            })
            .catch((error) => {
                feedbackCtx?.openErrorSnackbar(`Error: check your code!`)
            });
    }

    const ForgotPasswordModal = (): ReactElement => {
        const [email, setEmail] = useState<string>("")
        return <DefaultModal
            title={"Recover password"}
            description={"Type your email to recover the password."}
            confirmLabel={"Send"}
            cancelLabel={"Cancel"}
            handleCancel={() => {
                modalCtx?.closeModal()
            }}
            handleConfirm={() => {
                axios.put(`${BASE_URL}/auth/recover_password`, {email: email}).then(() => {
                    modalCtx?.closeModal()
                    feedbackCtx?.openSuccessSnackbar("Check your mail to reset the password.")
                }).catch((error) => {
                    if (error.response.status === 401) {
                        feedbackCtx?.openErrorSnackbar("Error: this email is not registered.")
                    }
                    if (error.response.status === 400) {
                        feedbackCtx?.openErrorSnackbar("Error: invalid format.")
                    }
                })
            }}
            buttonStyles={{
                buttonVariant: "outlined"
            }}
            confirmButtonDisabled={!email || email === ""}
        >
            <TextField fullWidth placeholder={"Email"} value={email} onChange={(e) => {
                    setEmail(e.target.value)
            }}/>
        </DefaultModal>
    }

    const openForgotPasswordModal = (): void => {
        modalCtx?.openModal(
            {
                component: <ForgotPasswordModal/>
            }
        )
    }

    return (
        <>
        {
            smallerScreen?
                <Grid container className={classes.mainContainer}>
                    <Grid item className={classes.textContainer}>
                        <Typography variant={"pageTitle"}>To use this app, please use a wider screen.</Typography>
                    </Grid>
                </Grid>
                :
                <AuthLayout>
                    {
                        !hasRefreshToken
                            ?
                            <AuthFormHeader>
                                <LoginForm submitCallback={postLogin} openForgotPasswordModal={openForgotPasswordModal}/>
                            </AuthFormHeader>
                            :
                            isMfaEnabled === undefined ?
                                <Grid container className={classes.loggerContainer}>
                                    <Grid item lg={12} className={classes.loader}>
                                        <CircularProgress size={150}/>
                                    </Grid>
                                </Grid>
                                :
                                isMfaEnabled ?
                                    <MultiFactorAuthForm authenticated={authenticated} submitCallback={postOtp} backCallback={() => {
                                        authCtx.logout(false).then(() => {
                                            setHasRefreshToken(false)
                                        })
                                    }}/> :
                                    <QRCodeGenerator secret={mfaSecret?.otp_secret} url={mfaSecret?.otp_url} submitCallback={postOtp}/>
                    }
                </AuthLayout>
        }
        </>
    )

}


