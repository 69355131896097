import {ListMeta, MetaField} from "../../types/meta";
import React, {ReactElement} from "react";
import {Grid, Input, Typography} from "@mui/material";
import {Control, FieldValues, Path, useController} from "react-hook-form";
import {Field} from "../../types/field";

interface NumberProps {
    label?: string;
    data?: number | string | any[];
    isLink?: boolean;
}

interface NumberFormProps<T> {
    formControl: Control,
    name: Path<FieldValues>
    field: Field<T>
}

function NumberShow({label, data, isLink}: NumberProps): ReactElement {
    return (
        <Grid container justifyContent={"space-between"}>
        <>
            <Grid item>
                <Typography variant={"dataTitle"}>
                    {label}
                </Typography>
            </Grid>
            <Grid item paddingBottom={"1em"}>
                <Typography variant={"body1"} fontWeight={isLink? 600 : 400}>
                    { !data ? undefined :
                        typeof data === "object" ? (data as [object]).length.toString() :
                            JSON.stringify(data)}
                </Typography>
            </Grid>
        </>
        </Grid>
    )
}

function NumberList<T>(data: T | undefined, field: Field<T>, isLink?: boolean): ReactElement {
    return <Typography noWrap variant={"body1"} fontWeight={isLink? 600 : 400}>{
                !data ? undefined :
                    typeof data[field.id] === "object" ? (data[field.id] as [object]).length.toString() :
                        JSON.stringify(data[field.id])
            }</Typography>
}

function NumberInput<T>(props : NumberFormProps<T>): ReactElement {

    const {field} = useController({
        name: props.name,
        control: props.formControl,
        rules: props.field.validationOptions
    })

    return <Input {...field} type={"number"}/>
}

export class NumberMeta<T> implements MetaField<T> {
    min?: number;
    max?: number;
    isLink?: boolean;

    constructor(props: NumberProps, min?: number, max?: number) {
        this.max = max;
        this.min = min;
        this.isLink = props.isLink;
    }
    
    getListMeta(): ListMeta {
        return ({
            type: 'number',
            value: (arg: any) => { return arg },
        })
    };

    getListComponent(data: T | undefined, field: Field<T>) {
        return () => {
            return NumberList(data, field, this.isLink)
        }
    }

    getFormComponent(control: Control, name: Path<FieldValues>, field: Field<T>): React.ReactElement {
        return NumberInput<T>({formControl: control, name: name, field: field});
    }

    getShowComponent(data: T | undefined, field: Field<T>) {
        return () => {
            return NumberShow({label: field.label, data: data ? data[field.id] as number : undefined, isLink: this.isLink});
        }
    }
}
