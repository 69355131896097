import React, {ReactElement, useState} from "react";
import {DefaultModal} from "../../../lib/components/modal/defaultModal";
import {useModalContext} from "../../../lib/context/ModalContext";
import {useFeedbackContext} from "../../../lib/context/FeedbackContext";
import {useLabTestDataProvider} from "../../../resources/labTest/hooks";
import {LabTestFilter} from "../../../resources/labTest/labTest/labTest";
import {Box, Button, Typography} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {ModalStyles} from "./modalStyles";
import {AxiosError} from "axios";
import {ErrorDTO} from "../../../hoc/axiosHandlerProvider/AxiosHandlerProvider";

interface BulkDownloadModalProps {
    ids: string[]
    filters?: LabTestFilter
}

export const BulkDownloadTestModal = ({ids, filters}: BulkDownloadModalProps): ReactElement => {
    const classes = ModalStyles();
    const modalCtx = useModalContext();
    const feedbackCtx = useFeedbackContext()

    const [loading, setLoading] = useState<boolean>(false);

    const [code, setCode] = useState<string>();
    const [copied, setCopied] = useState(false);

    const dataProvider = useLabTestDataProvider();

    const generateCode = () => {
        setLoading(true)
        dataProvider.asyncDownloadList(
            ids.length !== 0 ? ids.join(",") : undefined
            , filters).then((res) => {
            setLoading(false)
            setCode(res.ticket)
            return feedbackCtx.openBottomSuccessSnackbar("Code generated successfully.")
        }).catch((error) => {
            const axiosError = error as AxiosError<ErrorDTO, unknown>;
            setLoading(false)
            return feedbackCtx.openBottomErrorSnackbar(`Error: the code was not generated. Cause ${axiosError.response?.data.msg}`)
        })
    }

    const handleCopy = () => {
        if (code) {
            navigator.clipboard.writeText(code).then(() => {
                setCopied(true);
                return feedbackCtx.openBottomSuccessSnackbar("Code copied to clipboard.");
            });
        }
    };

    return (
            <DefaultModal
                title={"Download lab tests"}
                description={
                    loading? "Generating the code..." :
                    code? "Code generated." :
                    "Generate the code for the bulk download."}
                handleCancel={() => modalCtx?.closeModal()}
                handleConfirm={generateCode}
                cancelLabel={code? "Done" : "Cancel"}
                confirmLabel={code? undefined : "Generate code"}
                confirmButtonDisabled={loading}
                cancelButtonDisabled={loading}
            >
                <>
                    {
                    code &&
                        <Box>
                            <div className={classes.downloadBox}>
                                <Typography
                                    className={classes.code}
                                ><strong>{code}</strong></Typography>
                                <Button
                                    className={classes.copyButton}
                                    variant="outlined"
                                    onClick={handleCopy}
                                    disabled={copied}>
                                    <ContentCopyIcon fontSize={"small"}/>
                                    {copied ? "Copied" : "Copy Code"}
                                </Button>
                            </div>
                        </Box>
                    }
                </>
            </DefaultModal>
    )
}
